import { Routes } from '@angular/router';
import { CheckoutPage } from './checkout.page';
import { WholesalePaymentSuccessPage } from './wholesale-payment-success/wholesale-payment-success.page';

export const checkoutRoutes: Routes = [
    {
        path: '',
        component: CheckoutPage,
    },
    {
        path: 'wholesale-payment-success',
        component: WholesalePaymentSuccessPage,
    },
];
