<div class="card p-4">
    <div class="row">
        <div class="col-md-12 col-lg-4">
            <h2>{{ 'Requests'|translate }}</h2>
            <div class="my-4" *ngIf="requestLoading">
                <div class="spinner-border" role="status"></div>
            </div>
            <ng-container *ngIf="!requestLoading">
                <app-team-account-request
                    *ngFor="let request of requests"
                    [request]="request"
                    (answered)="initRequests()"
                ></app-team-account-request>
                <em *ngIf="requests.length === 0"> {{ 'You have no pending requests'|translate }} </em>
            </ng-container>
        </div>
        <div class="col-md-12 col-lg-8">
            <h2>{{ 'Your team members'|translate }}</h2>
            <div class="my-4" *ngIf="membersLoading">
                <div class="spinner-border" role="status"></div>
            </div>
            <ng-container *ngIf="!membersLoading">
                <app-team-account-member *ngFor="let member of members" [member]="member"></app-team-account-member>
                <em *ngIf="members.length === 0"> {{ 'You have no team members'|translate }} </em>
            </ng-container>
        </div>
    </div>
</div>
