import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { default as moment } from 'moment';
import { PhoneNumberFormComponent } from 'src/app/components/phone-number-form/phone-number-form.component';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { User } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';
import { ToastService } from '../../../../services/toast.service';
import { UserService } from '../../../../services/user.service';
import { CompanyUtils } from '../../../../utils/company.utils';

@Component({
    selector: 'app-profile-tab-personal-info',
    templateUrl: './profile-tab-personal-info.component.html',
    styleUrls: ['./profile-tab-personal-info.component.scss'],
    imports: [CommonModule, TranslatePipe, FormsModule, ReactiveFormsModule, PhoneNumberFormComponent],
})
export class ProfileTabPersonalInfoComponent implements OnInit {
    user: User;
    userForm: FormGroup;
    activities = CompanyUtils.activities;

    constructor(
        private readonly authService: AuthService,
        private readonly userService: UserService,
        private readonly toastService: ToastService,
    ) {}

    ngOnInit() {
        this.init();
    }

    private async init() {
        await LoadingUtils.show();
        this.authService.userLoggedIn.subscribe((user) => {
            if (user && !this.userForm) {
                this.user = user;
                const birthdate = user.birthdate ? moment(user.birthdate).format('YYYY-MM-DD') : null;
                // Create form
                this.userForm = new FormGroup({
                    firstname: new FormControl(this.user.firstname, Validators.required),
                    lastname: new FormControl(this.user.lastname, Validators.required),
                    phone: new FormControl(this.user.phone, Validators.required),
                    birthdate: new FormControl(birthdate),
                    description: new FormControl(this.user.description),
                });
                LoadingUtils.hide();
            }
        });
    }

    async save() {
        if (this.userForm.invalid) {
            return;
        }
        await LoadingUtils.show();
        const data = this.userForm.value;
        this.userService.updateMe(data).subscribe((user) => {
            this.user = user;
            this.authService.userLoggedIn.next(user);
            this.toastService.success(_('Changes saved'));
            LoadingUtils.hide();
        });
    }
}
