<ion-content>
    <div class="p-4 d-flex justify-content-between align-items-stretch flex-column min-h-100">
        <div>
            <div class="d-flex justify-content-end w-100">
                <span class="bi bi-x fs-3 clickable" (click)="cancel()"></span>
            </div>
            <div class="mt-2">
                <app-address-list [canSelect]="true" [(ngModel)]="pickedAddressId" [isProfile]="isProfile"></app-address-list>
            </div>
        </div>
        <div class="d-flex justify-content-end pt-2 gap-3">
            <button type="button" class="btn btn-outline-primary btn-block mt-4" (click)="cancel()">{{ "Cancel" | translate }}</button>
            <button type="button" class="btn btn-primary btn-block mt-4" (click)="pickAddress()">{{ "Select address" | translate }}</button>
        </div>
    </div>
</ion-content>
