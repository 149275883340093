import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { default as moment } from 'moment';
import { TeamAccountApproveModalComponent } from '../../modals/team-account-approve-modal/team-account-approve-modal.component';
import { TeamAccountRequest } from '../../models/company';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-team-account-request',
    templateUrl: './team-account-request.component.html',
    styleUrls: ['./team-account-request.component.scss'],
    imports: [CommonModule, TranslatePipe, CommonModule],
})
export class TeamAccountRequestComponent implements OnInit {
    @Output() answered = new EventEmitter<boolean>();
    private requestId: number;
    user: User;
    requestDate: string;
    isPro: boolean = false;
    userFullname: string;
    companyName: string;

    @Input() set request(req: TeamAccountRequest) {
        this.requestDate = moment(req.createdAt).format('DD.MM.YYYY HH:mm');
        this.userFullname = req.userFullname;
        this.companyName = req.companyName;
        this.requestId = req.id;
    }

    constructor(
        private readonly authService: AuthService,
        private readonly modalCtrl: ModalController,
    ) {
        this.authService.userLoggedIn.subscribe((user) => {
            if (user) {
                this.isPro = user.type === 'pro';
                this.user = user;
            }
        });
    }

    ngOnInit() {}

    async openApproveModal() {
        const modal = await this.modalCtrl.create({
            component: TeamAccountApproveModalComponent,
            componentProps: {
                request: {
                    id: this.requestId,
                    userFullname: this.userFullname,
                    companyName: this.companyName,
                },
            },
            cssClass: 'modal-auto-height',
        });
        modal.present();
        modal.onWillDismiss().then((res) => {
            if (res.data?.answered) {
                this.answered.emit(true);
            }
        });
    }
}
