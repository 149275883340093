import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Ad } from '../../../../models/ad';
import { AdService } from '../../../../services/ad.service';
import { AuthService } from '../../../../services/auth.service';
import { OfferService } from '../../../../services/offer.service';

@Component({
    selector: 'app-profile-tab-ads',
    templateUrl: './profile-tab-ads.page.html',
    styleUrls: ['./profile-tab-ads.page.scss'],
    imports: [CommonModule, TranslatePipe],
})
export class ProfileTabAdsPage implements OnInit {
    ads: Ad[];

    constructor(
        private readonly offerService: OfferService,
        private readonly authService: AuthService,
        private readonly adService: AdService,
    ) {}

    ngOnInit() {
        this.authService.userLoggedIn.subscribe((user) => {
            this.adService.getUserAds(user).subscribe((ads) => {
                this.ads = ads;
            });
        });
    }
}
