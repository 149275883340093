import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { filter, first } from 'rxjs';
import { AddressListComponent } from 'src/app/components/address-list/address-list.component';
import { AddressComponent } from 'src/app/components/address/address.component';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { PickAddressModalComponent } from '../../../../modals/pick-address-modal/pick-address-modal.component';
import { Address, User } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';
import { CompanyService } from '../../../../services/company.service';

@Component({
    selector: 'app-profile-tab-addresses',
    templateUrl: './profile-tab-addresses.component.html',
    styleUrls: ['./profile-tab-addresses.component.scss'],
    imports: [CommonModule, TranslatePipe, LoaderComponent, AddressListComponent, AddressComponent],
})
export class ProfileTabAddressesComponent implements OnInit {
    companyId: number;
    user: User;
    addresses: {
        shipping: Address;
        billing: Address;
        return: Address;
    } = {
        shipping: null,
        billing: null,
        return: null,
    };
    loading = true;

    constructor(
        private readonly authService: AuthService,
        private readonly modalCtrl: ModalController,
        private readonly companyService: CompanyService,
    ) {}

    ngOnInit() {
        this.authService.userLoggedIn
            .pipe(filter((user) => !!user))
            .pipe(first())
            .subscribe((user) => {
                this.user = user;
                if (this.user.type === 'pro') {
                    this.companyId = this.user.company;
                    this.loadCompanyAddresses();
                } else {
                    this.loading = false;
                }
            });
    }

    private loadCompanyAddresses() {
        this.loading = true;
        this.companyService.getAddresses(this.companyId).subscribe((addresses) => {
            console.log(addresses);
            this.addresses = addresses;
            this.loading = false;
        });
    }

    updateAddress(type: 'shipping' | 'billing' | 'return', addressId: number) {
        this.loading = true;

        // Prepare data
        // Update data with selected address
        const data: any = {};
        data[type] = addressId;

        // Update addresses
        this.companyService.updateAddresses(this.companyId, data).subscribe({
            next: (addresses) => {
                this.addresses = addresses;
                this.loading = false;
            },
        });
    }

    selectAddress(type: 'shipping' | 'billing' | 'return') {
        this.modalCtrl
            .create({
                component: PickAddressModalComponent,
                componentProps: {
                    type,
                    address: this.addresses[type],
                    isProfile: true,
                },
            })
            .then((modal) => {
                modal.present();
                modal.onDidDismiss().then((event) => {
                    if (event.data?.addressId) {
                        this.updateAddress(type, event.data?.addressId);
                    } else {
                        this.loadCompanyAddresses();
                    }
                });
            });
    }
}
