import { AbstractControl } from '@angular/forms';

export const IbanValidator = function (control: AbstractControl): { [key: string]: any } | null {
    const turkishIbanRegex = /^TR[0-9]{24}$/;
    let value = control.value;
    if (!value) {
        return null;
    }
    // Remove all spaces, convert to uppercase
    value = value.toUpperCase().replace(/\s/g, '');

    if (!turkishIbanRegex.test(value)) {
        return { iban: true };
    }

    return null;
};
