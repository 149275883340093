<ng-container *ngIf="!loaded else displayUserProfile">
    <div class="py-3 text-center">
        <app-loader></app-loader>
    </div>
</ng-container>
<ng-template #displayUserProfile>
    <div class="row g-4 align-items-center">
        <div class="col-12 text-center">
            <!-- Profile picture -->
            <div class="d-inline-block position-relative">
                <ng-container *ngIf="ppLoading else displayProfilePicture">
                    <div class="py-4 text-center">
                        <app-loader></app-loader>
                    </div>
                </ng-container>
                <ng-template #displayProfilePicture>
                    <app-picture *ngIf="user" [picture]="user?.picture" [default]="user.initials" size="100" format="circle"></app-picture>
                    <button type="button" class="btn btn-sm change-picture" (click)="changeProfilePicture()">
                        <i class="bi bi-arrow-repeat"></i>
                    </button>
                </ng-template>
            </div>
            <h4 class="mt-2 mb-0 p-0">{{ user.fullname }}</h4>
            <!-- Personal info -->
            <div>
                <div class="text-muted">
                    <ng-container>{{ user.email }}<br /></ng-container>
                    <ng-container *ngIf="user.phone">{{ user.phone }}<br /></ng-container>
                </div>
            </div>
        </div>
        <div class="col-12 text-center">
            <div class="row g-4 align-items-stretch" *ngIf="tabs">
                <div class="col-4 col-md-3 col-lg-2" *ngFor="let tab of tabs">
                    <a
                        class="card px-2 text-center py-3 h-100 d-flex flex-column justify-content-center align-items-center"
                        [routerLink]="tab.path"
                        routerLinkActive="active"
                    >
                        <i class="bi bi-{{ tab.icon }} fs-1"></i>
                        <h6 class="m-0 p-0 pt-2">{{ tab.name|translate }}</h6>
                    </a>
                </div>
                <div class="col-4 col-md-3 col-lg-2">
                    <div
                        class="card clickable px-2 text-center py-3 h-100 d-flex flex-column justify-content-center align-items-center"
                        (click)="logout()"
                    >
                        <i class="bi bi-box-arrow-right fs-1 text-danger"></i>
                        <h6 class="m-0 p-0 pt-2 text-muted">{{ 'Logout'|translate }}</h6>
                    </div>
                </div>
            </div>

            <!--    <h4 class="pt-4 pb-2">{{ 'Header'|translate }}</h4>-->
            <!--    <p>-->
            <!--      {{ 'user_header_explanation'|translate }}-->
            <!--    </p>-->
            <!--    <app-image-upload [loading]="ushLoading" [progress]="ushProgress" size="750x150">-->
            <!--      <app-picture *ngIf="user && user.searchHeader" [picture]="user.searchHeader" size="750x150"></app-picture>-->
            <!--    </app-image-upload>-->
            <!--    <button type="button" class="btn btn-primary px-3 mt-3" (click)="changeUserSearchHeader()">-->
            <!--      <i class="bi bi-pencil-fill me-2"></i> <span>{{ 'Change'|translate }}</span>-->
            <!--    </button>-->
        </div>
    </div>

    <!-- Company -->
    <div class="row g-4 mt-4">
        <div class="col-12 col-md-4 col-lg-3">
            <ng-container *ngIf="loaded && company">
                <div class="text-center">
                    <app-picture [picture]="company.picture" [default]="company.initials" size="100" format="circle"></app-picture>
                    <h4 class="mt-2 mb-0 p-0">{{ company.name }}</h4>
                </div>
            </ng-container>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <ng-container *ngIf="company else noCompanyPanel">
                <div class="card p-3" *ngIf="user.pro">
                    <h4>{{ 'Add team members'|translate }}</h4>
                    <p class="m-0">{{ 'Add team members explanation'|translate }}</p>
                    <p class="m-0 mt-2">
                        <span>{{ 'Company code'|translate }}</span> <span class="ps-4 fs-4 fw-bold">{{ company.code }}</span>
                    </p>
                </div>
            </ng-container>
            <ng-template #noCompanyPanel>
                <ng-container *ngIf="pendingRequests.length === 0">
                    <h3>{{ 'Register your company'|translate }}</h3>
                    <p>{{ 'Register your company explanation'|translate }}</p>
                    <button type="button" class="btn btn-primary btn-lg px-4" (click)="openProModal()" *ngIf="user.type === 'personal'">
                        <i class="bi bi-star-fill me-2"></i> <span>{{ 'Become pro'|translate }}</span>
                    </button>
                    <h3 class="mt-6">{{ 'Join'|translate }}</h3>
                    <p>{{ 'Join explanation'|translate }}</p>
                    <div class="d-flex justify-content-start align-items-center form-group gap-2" [formGroup]="companyCodeForm">
                        <input type="text" class="form-control" formControlName="code" />
                        <button type="button" class="btn btn-primary px-4" [disabled]="joiningCompany" (click)="joinCompany()">
                            {{ 'Join'|translate }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="pendingRequests.length > 0">
                    <h3>{{ 'Team account'|translate }}</h3>
                    <div *ngFor="let req of pendingRequests">
                        <app-team-account-request [request]="req"></app-team-account-request>
                    </div>
                </ng-container>
            </ng-template>
        </div>
        <div class="col-12 col-md-6 col-lg-5 col-xl-4" *ngIf="todoList">
            <div class="card p-3">
                <app-todo-list [todoList]="todoList"></app-todo-list>
                <app-validation-status [user]="user" [todoList]="todoList"></app-validation-status>
            </div>
        </div>
    </div>
    <div class="separator my-4"></div>
    <div class="d-flex justify-content-end align-items-center">
        <button class="btn btn-link" (click)="openAnonymizeModal()">{{ 'Delete my account'|translate }}</button>
    </div>
</ng-template>
