import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { filter, first } from 'rxjs';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { PictureComponent } from 'src/app/components/picture/picture.component';
import { PriceComponent } from 'src/app/components/price/price.component';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { UpdateOfferModalComponent } from '../../../../modals/update-offer-modal/update-offer-modal.component';
import { ModalContent } from '../../../../models/modal-content';
import { Offer } from '../../../../models/offer';
import { User } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';
import { ModalService } from '../../../../services/modal.service';
import { OfferService } from '../../../../services/offer.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
    selector: 'app-profile-tab-offers',
    templateUrl: './profile-tab-offers.page.html',
    styleUrls: ['./profile-tab-offers.page.scss'],
    imports: [CommonModule, TranslatePipe, LoaderComponent, PriceComponent, PictureComponent],
})
export class ProfileTabOffersPage implements OnInit {
    offers: Offer[];
    user: User;

    constructor(
        private readonly offerService: OfferService,
        private readonly authService: AuthService,
        private readonly modalService: ModalService,
        private readonly toastService: ToastService,
        private readonly modalCtrl: ModalController,
    ) {}

    ngOnInit() {
        this.authService.userLoggedIn
            .pipe(filter((user) => !!user))
            .pipe(first())
            .subscribe({
                next: (user) => {
                    this.user = user;
                    this.loadOffers();
                },
            });
    }

    private loadOffers() {
        this.offerService.getUserOffer(this.user).subscribe((offers) => {
            this.offers = offers;
        });
    }

    removeOffer(offer: Offer) {
        const content: ModalContent = {
            title: _('Remove offer'),
            message: _('Are you sure you want to remove this offer?'),
            confirmText: _('Remove'),
            cancelText: _('Cancel'),
            callback: async (data) => {
                if (data.action === 'confirm') {
                    await LoadingUtils.show();
                    this.offerService.removeOffer(offer).subscribe({
                        next: () => {
                            this.toastService.success(_('Your offer has been removed, other users will no longer see it'));
                            this.loadOffers();
                            LoadingUtils.hide();
                        },
                    });
                }
            },
        };
        this.modalService.create(content);
    }

    updateOffer(offer: Offer) {
        this.modalCtrl
            .create({
                component: UpdateOfferModalComponent,
                componentProps: { offer },
            })
            .then((modal) => {
                modal.present();
                modal.onDidDismiss().then(() => {
                    this.offers = null;
                    this.loadOffers();
                });
            });
    }
}
