<div class="card p-4">
    <ng-container *ngIf="!loading; else loadingContainer">
        <h2>{{ "Size guide" | translate }}</h2>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group" [formGroup]="form">
                    <label for="name" class="required">{{ "Size guide name" | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        formControlName="name"
                        [class.is-invalid]="form.get('name').invalid && form.get('name').touched"
                    />
                </div>
            </div>
        </div>
        <div class="separator my-2"></div>
        <div class="table-responsive">
            <table class="table table-borderless w-auto" [formGroup]="form">
                <thead>
                    <tr>
                        <th class="text-center">{{ "Size" | translate }}</th>
                        <th *ngFor="let column of columns; let i = index">
                            <div class="d-inline-flex align-items-center justify-content-center">
                                <div class="me-2 text-danger clickable">
                                    <i class="bi bi-trash me-1" (click)="removeColumn(i)"></i>
                                </div>
                                <select
                                    class="form-select"
                                    [formControlName]="column.colInputId"
                                    [class.is-invalid]="form.get(column.colInputId).invalid && form.get(column.colInputId).touched"
                                >
                                    <option value="">{{ "Select measurement" | translate }}</option>
                                    <option *ngFor="let part of measurements" [value]="part.value">{{ part.name | translate }}</option>
                                </select>
                            </div>
                        </th>
                        <th>
                            <button class="btn btn-primary" (click)="addColumn()">
                                <i class="bi bi-plus"></i>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of lines">
                        <td>
                            <div class="d-inline-flex align-items-center">
                                <div class="me-2 text-danger clickable" (click)="removeLine(line)">
                                    <i class="bi bi-trash me-1"></i>
                                </div>
                                <input
                                    type="text"
                                    class="form-control"
                                    [formControlName]="line.lineInputId"
                                    placeholder="{{ 'Size' | translate }}"
                                    [class.is-invalid]="form.get(line.lineInputId).invalid && form.get(line.lineInputId).touched"
                                />
                            </div>
                        </td>
                        <td *ngFor="let cell of line.cells">
                            <input
                                type="text"
                                class="form-control"
                                [formControlName]="cell.inputId"
                                placeholder="{{ 'Measurement (cm)' | translate }}"
                                [class.is-invalid]="form.get(cell.inputId).invalid && form.get(cell.inputId).touched"
                            />
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="100%">
                            <button class="btn btn-primary" (click)="addLine()">
                                <i class="bi bi-plus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="separator my-2"></div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary" (click)="save()">{{ "Save" | translate }}</button>
            </div>
        </div>
    </ng-container>
    <ng-template #loadingContainer>
        <div class="text-center py-4">
            <app-loader></app-loader>
        </div>
    </ng-template>
</div>
