import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { ShippingService } from '../../../../services/shipping.service';

@Component({
    selector: 'app-profile-tab-order-shipment-tracker',
    templateUrl: './profile-tab-order-shipment-tracker.component.html',
    styleUrls: ['./profile-tab-order-shipment-tracker.component.scss'],
    imports: [CommonModule, TranslatePipe, LoaderComponent, DatePipe],
})
export class ProfileTabOrderShipmentTrackerComponent implements OnInit {
    tracking: {
        deliveryLocation: string;
        barcode: string;
        status: string;
        history: {
            date: string;
            location: string;
            status: string;
        }[];
    };
    notFound: boolean = false;
    code: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly shippingService: ShippingService,
        private readonly navCtrl: NavController,
    ) {}

    ngOnInit() {
        this.code = this.route.snapshot.paramMap.get('code');
        this.shippingService.getShippingInfo(this.code).subscribe({
            next: (data) => {
                this.tracking = data;
                if (this.tracking.history.length === 0) {
                    this.notFound = true;
                } else {
                    this.tracking = data;
                    this.notFound = false;
                }
                console.log(data);
            },
            error: () => {
                this.notFound = true;
            },
        });
    }

    back() {
        this.navCtrl.back();
    }
}
