import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user';

@Component({
    selector: 'app-team-account-member',
    templateUrl: './team-account-member.component.html',
    styleUrls: ['./team-account-member.component.scss'],

    standalone: true,
})
export class TeamAccountMemberComponent implements OnInit {
    @Input() member: User;

    constructor() {}

    ngOnInit() {}
}
