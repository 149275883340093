import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { PictureComponent } from 'src/app/components/picture/picture.component';
import { TeamAccountRequestComponent } from 'src/app/components/team-account-request/team-account-request.component';
import { TodoListComponent } from 'src/app/components/todo-list/todo-list.component';
import { ValidationStatusComponent } from 'src/app/components/validation-status/validation-status.component';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { CompanyModalComponent } from '../../../../modals/company-modal/company-modal.component';
import { Company, TeamAccountRequest } from '../../../../models/company';
import { Media } from '../../../../models/media';
import { ModalContent } from '../../../../models/modal-content';
import { TodoList } from '../../../../models/todo-list';
import { User } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';
import { CompanyService } from '../../../../services/company.service';
import { MediaService } from '../../../../services/media.service';
import { ModalService } from '../../../../services/modal.service';
import { ToastService } from '../../../../services/toast.service';
import { TodoListService } from '../../../../services/todo-list.service';
import { UserService } from '../../../../services/user.service';
import { ProfileTabs, Tab } from '../../../../utils/profile-tabs';

@Component({
    selector: 'app-profile-tab-profile',
    templateUrl: './profile-tab-profile.page.html',
    styleUrls: ['./profile-tab-profile.page.scss'],
    imports: [
        CommonModule,
        TranslatePipe,
        LoaderComponent,
        RouterLink,
        RouterLinkActive,
        PictureComponent,
        FormsModule,
        ReactiveFormsModule,
        ValidationStatusComponent,
        TodoListComponent,
        TeamAccountRequestComponent,
    ],
})
export class ProfileTabProfilePage implements OnInit {
    user: User;
    company: Company;
    ppLoading = false;
    ppProgress = 0;
    cpLoading = false;
    cpProgress = 0;
    joiningCompany = false;
    pendingRequests: TeamAccountRequest[] = [];
    loaded = false;
    companyCodeForm = new FormGroup({
        code: new FormControl('', Validators.required),
    });
    cshLoading = false;
    cshProgress: number;
    ushLoading = false;
    ushProgress: number;
    tabs: Tab[] = [];
    todoList: TodoList;

    constructor(
        private readonly authService: AuthService,
        private readonly userService: UserService,
        protected readonly modalCtrl: ModalController,
        private readonly mediaService: MediaService,
        private readonly companyService: CompanyService,
        private readonly toastService: ToastService,
        private readonly modalService: ModalService,
        protected readonly navCtrl: NavController,
        private readonly todoListService: TodoListService,
    ) {}

    ngOnInit() {
        this.authService.userLoggedIn.subscribe((user) => {
            if (user && this.user) {
                this.user = user;
            }
            if (user) {
                this.reloadData(user);
            }
        });
    }

    private async reloadData(user: User) {
        this.user = user;
        this.tabs = ProfileTabs.getTabs(user);
        console.log(this.user);
        this.todoList = await this.todoListService.create(user);
        // Check if user type is pro or team
        if (user.type === 'pro' || user.type === 'team') {
            // Get company data
            this.companyService.getMyCompany(user.id).subscribe({
                next: (company) => {
                    this.company = company;
                    this.loaded = true;
                    this.tabs = ProfileTabs.getTabs(user, company);
                },
            });
        } else {
            this.initRequests();
        }
    }

    initRequests() {
        this.companyService.getMyTeamAccountRequests().subscribe({
            next: (requests) => {
                this.pendingRequests = requests;
                this.loaded = true;
            },
        });
    }

    openProModal() {
        this.modalCtrl
            .create({
                component: CompanyModalComponent,
            })
            .then((modalEl) => {
                modalEl.present();
                modalEl.onWillDismiss().then((resultData) => {
                    if (!resultData.data?.error) {
                        this.userService.refreshUserData().then((user) => {
                            this.user = user;
                            this.reloadData(user);
                        });
                    }
                });
            });
    }

    private sendProfilePicture(media: Media) {
        this.userService.updateProfilePicture(media).subscribe({
            next: (user) => {
                this.user = user;
                this.ppProgress = 100;
                this.ppLoading = false;
            },
            error: (err) => {
                this.ppLoading = false;
                console.error(err);
            },
        });
    }

    private sendUserSearchHeader(media: Media) {
        this.userService.updateMe({ searchHeader: media.id }).subscribe({
            next: (user) => {
                this.user.searchHeader = user.searchHeader;
                this.ushProgress = 100;
                this.ushLoading = false;
            },
            error: (err) => {
                this.ushLoading = false;
                console.error(err);
            },
        });
    }

    changeProfilePicture() {
        this.mediaService
            .pickImage()
            .then((file) => {
                this.ppProgress = 0;
                this.ppLoading = true;
                this.mediaService.upload(
                    file.blob,
                    file.filename,
                    'avatar',
                    (progress) => {
                        this.ppProgress = progress;
                    },
                    (media) => {
                        this.sendProfilePicture(media);
                    },
                );
            })
            .catch(() => {
                this.ppLoading = false;
            });
    }

    joinCompany() {
        if (!this.companyCodeForm.valid) {
            return;
        }
        this.joiningCompany = true;
        this.companyService.join(this.companyCodeForm.value.code).subscribe({
            next: (request) => {
                this.joiningCompany = false;
                this.pendingRequests.push(request);
                this.toastService.success(_('The request has been sent to your employer'));
            },
            error: (response) => {
                this.joiningCompany = false;
                this.toastService.error(response.error.error);
            },
        });
    }

    changeUserSearchHeader() {
        this.mediaService
            .pickImage()
            .then((file) => {
                this.ushProgress = 0;
                this.ushLoading = true;
                this.mediaService.upload(
                    file.blob,
                    file.filename,
                    'search_header',
                    (progress) => {
                        this.ushProgress = progress;
                    },
                    (media) => {
                        this.sendUserSearchHeader(media);
                    },
                );
            })
            .catch(() => {
                this.ushLoading = false;
            });
    }

    openAnonymizeModal() {
        const content: ModalContent = {
            icon: 'bi-trash',
            title: _('Are you sure?'),
            message: _('This action is irreversible, you will lose access to your data'),
            confirmText: _('Yes, remove my account'),
            cancelText: _('Cancel'),
            callback: async (data) => {
                if (data.action === 'confirm') {
                    // create loader and do anonymize
                    await LoadingUtils.show();
                    this.userService.anonymizeMe().subscribe({
                        next: () => {
                            this.authService.logout();
                            LoadingUtils.hide();
                            this.toastService.success(_('Your account has been removed'));
                            this.navCtrl.navigateRoot('/');
                        },
                    });
                }
            },
        };
        this.modalService.create(content);
    }

    logout() {
        this.authService.logout(true);
    }
}
