<ng-container *ngIf="company; else loadingCompany">
    <div class="row">
        <!-- Company -->
        <div class="col-12 col-lg-6 mb-4">
            <div *ngIf="company">
                <div class="card p-3">
                    <div class="d-flex justify-content-center align-items-center flex-column gap-3">
                        <h3>{{ company.name }}</h3>
                        <ng-container *ngIf="cpLoading; else displayCompanyPicture">
                            <div class="py-4 text-center">
                                <app-loader></app-loader>
                            </div>
                        </ng-container>
                        <ng-template #displayCompanyPicture>
                            <div class="d-inline-block position-relative">
                                <app-picture
                                    [picture]="company.picture"
                                    [default]="company.initials"
                                    size="100"
                                    format="circle"
                                ></app-picture>
                                <button
                                    type="button"
                                    class="btn btn-sm change-picture"
                                    (click)="changeCompanyPicture()"
                                    *ngIf="user.type === 'pro'"
                                >
                                    <i class="bi bi-arrow-repeat"></i>
                                </button>
                            </div>
                        </ng-template>
                        <div>
                            <div class="data-entry">
                                <strong>{{ "Registration number:" | translate }}</strong
                                ><span>{{ company.registrationNumber }}</span>
                            </div>
                            <div class="data-entry">
                                <strong>{{ "VAT Number:" | translate }}</strong
                                ><span>{{ company.vatNumber }}</span>
                            </div>
                            <div class="data-entry">
                                <strong>{{ "Activity:" | translate }}</strong
                                ><span>{{ company.activity | translate }}</span>
                            </div>
                            <div class="data-entry" *ngIf="company.activity === 'real_estate'">
                                <strong>{{ "company_activity.real_estate.pro_number" | translate }}</strong
                                ><span>{{ company.proNumber }}</span>
                            </div>
                            <div class="data-entry" *ngIf="company.activity === 'real_estate'">
                                <strong>{{ "company_activity.real_estate.authorization_number" | translate }}</strong
                                ><span>{{ company.authorizationNumber }}</span>
                            </div>
                            <div class="data-entry" *ngIf="company.activity === 'car_dealership'">
                                <strong>{{ "company_activity.car_dealership.authorization_number" | translate }}</strong
                                ><span>{{ company.authorizationNumber }}</span>
                            </div>
                            <div class="text-center">
                                <button (click)="changeCompanyInfo()" class="btn btn-link">{{ "Change" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Team -->
                <div class="mt-4 card p-3">
                    <h4>{{ "Add team members" | translate }}</h4>
                    <p class="m-0">
                        {{ "Add team members explanation" | translate }}
                    </p>
                    <p class="m-0 mt-2">
                        <span>{{ "Company code" | translate }}</span> <span class="ps-4 fs-4 fw-bold">{{ company.code }}</span>
                    </p>
                </div>

                <!-- Search header -->
                <div class="mt-4 card p-3">
                    <h4>{{ "Header" | translate }}</h4>
                    <p>
                        {{ "company_header_explanation" | translate }}
                    </p>
                    <app-image-upload [loading]="cshLoading" [progress]="cshProgress" size="750x150">
                        <app-picture *ngIf="company && company.searchHeader" [picture]="company.searchHeader" size="750x150"></app-picture>
                    </app-image-upload>
                    <button
                        type="button"
                        class="btn btn-primary px-3 mt-3"
                        (click)="changeCompanySearchHeader()"
                        *ngIf="user.type === 'pro'"
                    >
                        <i class="bi bi-pencil-fill me-2"></i> <span>{{ "Change" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
            <div class="card p-3">
                <h3>{{ "Documents" | translate }}</h3>
                <ng-container *ngIf="documents; else loadingDocument">
                    <ng-container *ngIf="documents.length === 0; else documentsList">
                        <p class="m-0">{{ "No documents" | translate }}</p>
                        <div class="separator my-3"></div>
                    </ng-container>
                    <ng-template #documentsList>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{{ "Document type" | translate }}</th>
                                    <th>{{ "Expiry date" | translate }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let d of documents">
                                    <td>{{ getLabelFromType(d.type) | translate }}</td>
                                    <td>{{ d.expiresAtFormatted }}</td>
                                    <td>
                                        <div class="d-flex justify-content-end align-items-center gap-2">
                                            <button class="btn btn-link-primary" (click)="downloadDocument(d)">
                                                <i class="bi bi-cloud-download clickable"></i>
                                            </button>
                                            <button class="btn btn-link-danger" (click)="deleteDocument(d)">
                                                <i class="bi bi-trash clickable"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </ng-container>
                <ng-template #loadingDocument>
                    <div class="text-center py-4"><app-loader></app-loader></div>
                </ng-template>
                <ng-container *ngIf="canAddDocuments()" [formGroup]="addDocumentForm">
                    <h4>{{ "Add documents" | translate }}</h4>
                    <div class="d-flex gap-3">
                        <div>
                            <label for="document" class="form-label required">{{ "Document type" | translate }}</label>
                            <select formControlName="type" class="form-select" id="document">
                                <option value="">{{ "Select document type" | translate }}</option>
                                <option *ngFor="let t of documentTypes" [value]="t.value">{{ t.label | translate }}</option>
                            </select>
                        </div>
                        <div>
                            <ng-container *ngIf="displayExpiresAtField()">
                                <label for="expiresAt" class="form-label required">{{ "Expiry date" | translate }}</label>
                                <input
                                    type="date"
                                    class="form-control"
                                    placeholder="{{ 'Expiry date' | translate }}"
                                    formControlName="expiresAt"
                                    id="expiresAt"
                                />
                            </ng-container>
                        </div>
                        <div class="ms-auto text-right">
                            <button type="button" (click)="uploadDocument()" class="btn btn-primary" [disabled]="addDocumentForm.invalid">
                                {{ "Pick file" | translate }}
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loadingCompany>
    <div class="text-center py-4"><app-loader></app-loader></div>
</ng-template>
