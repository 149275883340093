<div *ngIf="user && userForm">
    <form [formGroup]="userForm" (ngSubmit)="save()" class="card p-4">
        <div class="row">
            <!-- Firstname field -->
            <div class="col form-group">
                <label class="required">{{ "Firstname" | translate }}</label>
                <input class="form-control" formControlName="firstname" />
            </div>
            <!-- Lastname field -->
            <div class="col form-group">
                <label class="required">{{ "Lastname" | translate }}</label>
                <input class="form-control" formControlName="lastname" />
            </div>
        </div>
        <app-phone-number-form formControlName="phone" [isRequired]="true"></app-phone-number-form>
        <!-- Birthdate -->
        <div class="form-group">
            <label>{{ "Birthdate" | translate }}</label>
            <input class="form-control" formControlName="birthdate" type="date" />
        </div>
        <!-- Description -->
        <div class="form-group">
            <label>{{ "Description" | translate }}</label>
            <textarea class="form-control" formControlName="description" rows="10"></textarea>
        </div>
        <p class="d-flex justify-content-end">
            <button class="btn btn-primary" [disabled]="userForm.invalid">
                <!-- Loader when loading -->
                <span>{{ "Save" | translate }}</span>
            </button>
        </p>
    </form>
</div>
