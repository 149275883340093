import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { LocaleSettingsComponent } from 'src/app/components/locale-settings/locale-settings.component';

@Component({
    selector: 'app-settings-modal',
    templateUrl: './settings-modal.component.html',
    styleUrls: ['./settings-modal.component.scss'],
    imports: [CommonModule, IonContent, LocaleSettingsComponent, TranslatePipe],
})
export class SettingsModalComponent implements OnInit {
    constructor(private readonly modalCtrl: ModalController) {}

    ngOnInit() {}

    cancel() {
        this.modalCtrl.dismiss();
    }
}
