import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { SizeGuide, SizeGuideColumn, SizeGuideLine } from '../../../../models/size-guide';
import { SizeGuideService } from '../../../../services/size-guide.service';
import { ToastService } from '../../../../services/toast.service';
import { RegexUtils } from '../../../../utils/regex.utils';

@Component({
    selector: 'app-profile-tab-size-guide',
    templateUrl: './profile-tab-size-guide.component.html',
    styleUrls: ['./profile-tab-size-guide.component.scss'],
    imports: [CommonModule, TranslatePipe, FormsModule, ReactiveFormsModule, LoaderComponent],
})
export class ProfileTabSizeGuideComponent implements OnInit {
    lines: SizeGuideLine[] = [];
    columns: SizeGuideColumn[] = [];
    sizeGuide: SizeGuide;
    form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
    });
    measurements = [
        { name: _('sizeguide.chest'), value: 'chest' },
        { name: _('sizeguide.waist'), value: 'waist' },
        { name: _('sizeguide.hips'), value: 'hips' },
        { name: _('sizeguide.shoulders'), value: 'shoulders' },
        { name: _('sizeguide.sleeve'), value: 'sleeve' },
        // {name: _('sizeguide.inseam'), value: 'inseam'},
        // {name: _('sizeguide.arm_length'), value: 'arm_length'},
        // {name: _('sizeguide.height'), value: 'height'},
        // {name: _('sizeguide.weight'), value: 'weight'},
        // {name: _('sizeguide.leg_length'), value: 'leg_length'},
        { name: _('sizeguide.neck'), value: 'neck' },
        // {name: _('sizeguide.head'), value: 'head'},
        { name: _('sizeguide.biceps'), value: 'biceps' },
        { name: _('sizeguide.back'), value: 'back' },
    ];
    loading = true;

    constructor(
        private readonly sizeGuideService: SizeGuideService,
        private readonly navCtrl: NavController,
        private readonly route: ActivatedRoute,
        private readonly toastService: ToastService,
    ) {}

    ngOnInit() {
        // Get the size guide id from the route if it exists
        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.sizeGuideService.get(parseInt(id)).subscribe((sizeGuide) => {
                this.sizeGuide = sizeGuide;
                this.lines = sizeGuide.guide.lines;
                this.columns = sizeGuide.guide.columns;
                console.log(this.lines, this.columns);
                this.form.get('name').setValue(sizeGuide.name);
                // Add form controls
                this.lines.forEach((line) => {
                    this.addLine(line);
                });
                this.columns.forEach((col) => {
                    this.addColumn(col);
                });
                this.loading = false;
            });
        } else {
            this.addLine();
            this.addColumn();
            this.addColumn();
            this.loading = false;
        }
    }

    addLine(line: SizeGuideLine = null) {
        // Add form controls for the name
        const lineInputId = line?.lineInputId || `line-${this.lines.length}-name`;
        this.form.addControl(lineInputId, new FormControl(line?.name || '', [Validators.required]));
        if (!line) {
            // Create new line
            line = {
                name: '',
                lineInputId: lineInputId,
                cells: this.columns.map((col, i) => ({
                    content: '',
                    inputId: `${this.lines.length}-${i}`,
                })),
            };
            this.lines.push(line);
        }

        // Add form controls for the cells
        line.cells.forEach((cell) => {
            this.form.addControl(
                cell.inputId,
                new FormControl(cell.content, [Validators.required, Validators.pattern(RegexUtils.SIZE_GUIDE_CELL_REGEX)]),
            );
        });
    }

    addColumn(col: SizeGuideColumn = null) {
        const colInputId = col?.colInputId || `col-${this.columns.length}-name`;
        if (!col) {
            col = {
                name: '',
                colInputId,
            };
            this.columns.push(col);
            // Also add a cell for each line
            this.lines.forEach((line, i) => {
                const inputId = `${i}-${this.columns.length}`;
                // Add form controls for the cells
                this.form.addControl(
                    inputId,
                    new FormControl('', [Validators.required, Validators.pattern(RegexUtils.SIZE_GUIDE_CELL_REGEX)]),
                );
                line.cells.push({
                    content: '',
                    inputId,
                });
            });
        }

        // Add form controls for the name
        this.form.addControl(colInputId, new FormControl(col.name, [Validators.required]));
    }

    async save() {
        // Mark form as marker
        this.form.markAllAsTouched();
        if (!this.form.valid || this.loading) {
            return;
        }

        await LoadingUtils.show();

        // Foreach line, update the name
        this.lines.forEach((line) => {
            line.name = this.form.get(line.lineInputId).value;
            // Foreach cell, update the content
            line.cells.forEach((cell) => {
                cell.content = this.form.get(cell.inputId).value;
            });
        });

        // Foreach column, update the name
        this.columns.forEach((col) => {
            col.name = this.form.get(col.colInputId).value;
        });

        if (this.sizeGuide?.id) {
            // Create size guide
            this.sizeGuideService
                .update(this.sizeGuide.id, {
                    name: this.form.get('name').value,
                    guide: JSON.stringify({
                        lines: this.lines,
                        columns: this.columns,
                    }),
                })
                .subscribe({
                    next: () => {
                        this.navCtrl.navigateBack('/profile/size-guide');
                        LoadingUtils.hide();
                    },
                    error: () => {
                        LoadingUtils.hide();
                        this.toastService.error(_('An error occurred'));
                    },
                });
        } else {
            // Create size guide
            this.sizeGuideService
                .create({
                    name: this.form.get('name').value,
                    guide: JSON.stringify({
                        lines: this.lines,
                        columns: this.columns,
                    }),
                })
                .subscribe({
                    next: () => {
                        this.navCtrl.navigateBack('/profile/size-guide');
                        LoadingUtils.hide();
                    },
                    error: () => {
                        LoadingUtils.hide();
                        this.toastService.error(_('An error occurred'));
                    },
                });
        }
    }

    removeColumn(i: number) {
        // Remove column from columns
        this.columns = this.columns.filter((col, index) => index !== i);
        // Remove column from form
        this.form.removeControl(`col-${i}-name`);
        // Remove cells from form
        this.lines.forEach((line) => {
            this.form.removeControl(`${line.cells[i].inputId}`);
            line.cells = line.cells.filter((cell, index) => index !== i);
        });
    }

    removeLine(line: SizeGuideLine) {
        // Remove line from lines
        this.lines = this.lines.filter((l) => l !== line);
        // Remove line from form
        this.form.removeControl(line.lineInputId);
        // Remove cells from form
        line.cells.forEach((cell) => {
            this.form.removeControl(cell.inputId);
        });
    }
}
