import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { filter, first } from 'rxjs';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { OrderSummaryComponent } from 'src/app/components/order-summary/order-summary.component';
import { PriceComponent } from 'src/app/components/price/price.component';
import { User } from 'src/app/models/user';
import { ShippingService } from 'src/app/services/shipping.service';
import { AlertUtils } from 'src/app/utils/alert.utils';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { Order } from '../../../../models/order';
import { AuthService } from '../../../../services/auth.service';
import { MediaService } from '../../../../services/media.service';
import { OrderService } from '../../../../services/order.service';

@Component({
    selector: 'app-profile-tab-order',
    templateUrl: './profile-tab-order.component.html',
    styleUrls: ['./profile-tab-order.component.scss'],
    imports: [
        CommonModule,
        TranslatePipe,
        FormsModule,
        ReactiveFormsModule,
        LoaderComponent,
        RouterLink,
        PriceComponent,
        DatePipe,
        OrderSummaryComponent,
    ],
})
export class ProfileTabOrderComponent implements OnInit {
    isBuyer = true;
    order: Order;
    isSeller: boolean;
    returnInvoiceAllowed = false;
    ref: string;
    user: User;

    constructor(
        private readonly modalCtrl: ModalController,
        private readonly mediaService: MediaService,
        private readonly authService: AuthService,
        private readonly orderService: OrderService,
        private readonly route: ActivatedRoute,
        private readonly navCtrl: NavController,
        private readonly shippingService: ShippingService,
    ) {}

    ngOnInit() {
        this.ref = this.route.snapshot.paramMap.get('ref');
        this.authService.userLoggedIn
            .pipe(
                filter((user) => !!user),
                first(),
            )
            .subscribe({
                next: async (user) => {
                    await LoadingUtils.show();
                    this.user = user;
                    this.refreshOrder();
                },
            });
    }

    refreshOrder() {
        this.orderService.getOrder(this.ref).subscribe({
            next: (order) => {
                this.order = order;
                this.isBuyer = this.user.id === order.buyer?.id;
                this.isSeller = this.user.id === order.seller?.id || this.user.company === order.company?.id;
                LoadingUtils.hide();
            },
        });
    }

    back() {
        this.navCtrl.back();
    }

    async createInvoice() {
        const media = await this.mediaService.pickDocument('invoice');
        if (!media) {
            return;
        }

        // Upload the invoice
        this.orderService
            .createInvoice({
                mediaId: media.id,
                orderId: this.order.id,
            })
            .subscribe({
                next: async () => {
                    this.refreshOrder();
                },
                error: () => {
                    AlertUtils.showError(_('An error occurred'));
                    LoadingUtils.hide();
                },
            });
    }

    downloadInvoice() {
        LoadingUtils.show();
        this.orderService.downloadInvoice(this.order.invoice.id).subscribe({
            next: async (response) => {
                LoadingUtils.hide();
                Browser.open({
                    url: response.url,
                    windowName: '_system',
                });
            },
        });
    }

    redirectToContract() {
        Browser.open({
            url: this.orderService.getDownloadUrlContractInvoice(this.order.ref),
            windowName: '_system',
        });
    }

    shipOrder() {
        AlertUtils.askConfirmation(_('Are you sure you want to ship this order?')).then((confirmed) => {
            if (!confirmed) {
                return;
            }
            LoadingUtils.show();

            this.shippingService.shipOrder(this.order).subscribe({
                next: (order) => {
                    this.order = order;
                    LoadingUtils.hide();
                },
            });
        });
    }
}
