import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { checkoutRoutes } from './pages/checkout/checkout.routes';
import { profileRoutes } from './pages/profile/profile.routes';

export const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'platform' },
    {
        path: 'home',
        loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage),
    },
    {
        path: 'offers/:id',
        loadComponent: () => import('./pages/offer/offer.page').then((m) => m.OfferPage),
    },
    {
        path: 'offers/:id-:slug',
        loadComponent: () => import('./pages/offer/offer.page').then((m) => m.OfferPage),
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/profile/profile.page').then((m) => m.ProfilePage),
        children: profileRoutes,
    },
    {
        path: 'search',
        loadComponent: () => import('./pages/search/search.page').then((m) => m.SearchPage),
    },
    {
        path: 'signup',
        loadComponent: () => import('./pages/signup/signup.page').then((m) => m.SignupPage),
    },
    {
        path: 'create-offer',
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/create-offer/create-offer.page').then((m) => m.CreateOfferPage),
    },
    {
        path: 'cart',
        loadComponent: () => import('./pages/shopping-cart/shopping-cart.page').then((m) => m.ShoppingCartPage),
    },
    {
        path: 'faq',
        loadComponent: () => import('./pages/faq/faq.page').then((m) => m.FaqPage),
    },
    {
        path: 'checkout',
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/checkout/checkout.page').then((m) => m.CheckoutPage),
        children: checkoutRoutes,
    },
    {
        path: 'contact',
        loadComponent: () => import('./pages/contact/contact.page').then((m) => m.ContactPage),
    },
    {
        path: 'platform',
        loadComponent: () => import('./pages/platform/platform.page').then((m) => m.PlatformPage),
    },
    {
        path: 'support',
        loadComponent: () => import('./pages/support/support.page').then((m) => m.SupportPage),
    },
    {
        path: 'settings',
        loadComponent: () => import('./pages/settings/settings.page').then((m) => m.SettingsPage),
    },
    {
        path: 'transactions/:code',
        loadComponent: () => import('./pages/transaction/transaction.page').then((m) => m.TransactionPage),
    },
    // slug only : always last
    {
        path: ':slug',
        loadComponent: () => import('./pages/content-page/content-page.page').then((m) => m.ContentPagePage),
    },
];
