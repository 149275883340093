<div class="d-flex justify-content-between align-items-center mb-3">
    <h1 class="m-0 p-0">{{ "Create shipment sticker" | translate }}</h1>
    <button type="button" class="btn btn-outline-primary" (click)="addShipmentForm()">{{ "Add package" | translate }}</button>
</div>
<form (submit)="submit()" [formGroup]="form" *ngIf="form">
    <div class="row">
        <div class="col-4" [formGroupName]="'shipping'">
            <div class="card p-3">
                <h4>{{ "Customer" | translate }}</h4>
                <!-- Fullname -->
                <div class="form-group">
                    <label>{{ "Fullname" | translate }}</label>
                    <input type="text" class="form-control" formControlName="fullname" />
                </div>
                <!-- Phone -->
                <div class="form-group">
                    <app-phone-number-form formControlName="phone"></app-phone-number-form>
                </div>
                <!-- Email -->
                <div class="form-group">
                    <label>{{ "Email" | translate }}</label>
                    <input type="text" class="form-control" formControlName="email" />
                </div>
                <!-- Street -->
                <div class="form-group">
                    <label>{{ "Street" | translate }}</label>
                    <input type="text" class="form-control" formControlName="street" />
                </div>
                <!-- Region -->
                <div class="form-group">
                    <label>{{ "Region" | translate }}</label>
                    <input type="text" class="form-control" formControlName="region" />
                </div>
                <!-- City -->
                <div class="form-group">
                    <label>{{ "City" | translate }}</label>
                    <input type="text" class="form-control" formControlName="city" />
                </div>
                <!-- Zip -->
                <div class="form-group">
                    <label>{{ "Zip code" | translate }}</label>
                    <input type="text" class="form-control" formControlName="zip" />
                </div>
            </div>
        </div>
        <div class="col-4" [formGroupName]="'return'">
            <div class="card p-3">
                <h4>{{ "Return information" | translate }}</h4>
                <!-- Fullname -->
                <div class="form-group">
                    <label>{{ "Fullname" | translate }}</label>
                    <input type="text" class="form-control" formControlName="fullname" />
                </div>
                <!-- Phone -->
                <div class="form-group">
                    <app-phone-number-form formControlName="phone"></app-phone-number-form>
                </div>
                <!-- Email -->
                <div class="form-group">
                    <label>{{ "Email" | translate }}</label>
                    <input type="text" class="form-control" formControlName="email" />
                </div>
                <!-- Street -->
                <div class="form-group">
                    <label>{{ "Street" | translate }}</label>
                    <input type="text" class="form-control" formControlName="street" />
                </div>
                <!-- Region -->
                <div class="form-group">
                    <label>{{ "Region" | translate }}</label>
                    <input type="text" class="form-control" formControlName="region" />
                </div>
                <!-- City -->
                <div class="form-group">
                    <label>{{ "City" | translate }}</label>
                    <input type="text" class="form-control" formControlName="city" />
                </div>
                <!-- Zip -->
                <div class="form-group">
                    <label>{{ "Zip code" | translate }}</label>
                    <input type="text" class="form-control" formControlName="zip" />
                </div>
            </div>
        </div>
        <div class="col-4">
            <ng-container *ngFor="let shipmentForm of getShipmentForms(); let i = index">
                <div class="card p-3 mb-3" [formGroup]="shipmentForm">
                    {{ "Package" | translate }} {{ i + 1 }}
                    <div class="row">
                        <div class="col-6">
                            <!-- deci -->
                            <div class="form-group">
                                <label>{{ "Deci" | translate }}</label>
                                <app-deci-helper
                                    formControlName="deci"
                                    [isInvalid]="shipmentForm.get('deci').invalid && shipmentForm.get('deci').touched"
                                ></app-deci-helper>
                            </div>
                        </div>
                        <div class="col-6">
                            <!-- weight -->
                            <div class="form-group">
                                <label>{{ "Weight (g)" | translate }}</label>
                                <input type="number" class="form-control" formControlName="weight" />
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <button type="button" class="btn btn-outline-danger" (click)="removeShipmentForm(i)">
                            {{ "Remove" | translate }}
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="form-group d-flex justify-content-end align-items-center gap-4">
        <button type="submit" class="btn btn-primary" [disabled]="form.invalid">{{ "Save" | translate }}</button>
    </div>
</form>
