import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Card } from '../models/transaction';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class CardService {
    constructor(private readonly apiService: ApiService) {}

    list(): Observable<Card[]> {
        return this.apiService.get<Card[]>('/api/cards').pipe(map((cards) => cards.map((c) => new Card(c))));
    }

    deleteCard(card: Card): Observable<any> {
        return this.apiService.delete(`/api/cards/${card.id}`);
    }
}
