import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonContent } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import Decimal from 'decimal.js';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { OrderSummaryComponent } from 'src/app/components/order-summary/order-summary.component';
import { PriceComponent } from 'src/app/components/price/price.component';
import { PriceObject } from 'src/app/models/offer';
import { Order } from 'src/app/models/order';
import { OrderService } from 'src/app/services/order.service';

@Component({
    selector: 'app-wholesale-payment-success',
    templateUrl: './wholesale-payment-success.page.html',
    styleUrls: ['./wholesale-payment-success.page.scss'],
    imports: [CommonModule, IonContent, LoaderComponent, TranslatePipe, PriceComponent, OrderSummaryComponent],
})
export class WholesalePaymentSuccessPage implements OnInit {
    orders: Order[];
    totalAmountToBePaid: PriceObject;
    contracts: { name: string; url: string }[];

    constructor(
        private route: ActivatedRoute,
        private orderService: OrderService,
    ) {}

    ngOnInit() {}

    async ionViewWillEnter() {
        const orderIds = this.route.snapshot.queryParams['orders']?.split(',') || [];
        this.orders = await Promise.all(
            orderIds.map((id: string) => new Promise<Order>((resolve) => this.orderService.getOrder(id).subscribe(resolve))),
        );
        this.totalAmountToBePaid = {
            price: this.orders.reduce((acc: Decimal, order: Order) => acc.plus(new Decimal(order.total)), new Decimal('0')),
            currency: 'TRY',
        };
        this.contracts = [];
    }
}
