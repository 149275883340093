<div class="card p-3" *ngIf="user">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <h4 *ngIf="!isPro">{{ companyName }}</h4>
            <h4 *ngIf="isPro">{{ userFullname }}</h4>
            <p class="m-0 p-0" *ngIf="!isPro">
                {{ "Request sent on -date-" | translate: { date: requestDate } }}
            </p>
            <p class="m-0 p-0" *ngIf="isPro">
                {{ "Request received on -date-" | translate: { date: requestDate } }}
            </p>
        </div>
        <div>
            <button type="button" class="btn btn-link-primary" *ngIf="isPro" (click)="openApproveModal()">
                <i class="bi bi-check-circle"></i> {{ "Answer" | translate }}
            </button>
        </div>
    </div>
</div>
