<!-- Main menu with categories -->
<ion-menu contentId="main-content" menuId="main-menu" id="main-menu">
    <ion-content>
        <div class="mx-3 h-100">
            <div
                class="h-100 d-flex flex-column justify-content-between align-items-stretch"
                *ngIf="!menuCategorySelected; else showMenuSubCategory"
            >
                <!-- Logo -->
                <div>
                    <ion-menu-toggle>
                        <a routerLink="/home" class="d-flex w-100 justify-content-center text-decoration-none" routerDirection="root">
                            <img ngSrc="/assets/avrazya-logo.svg" alt="Avrazya Logo" [width]="67" [height]="20" />
                        </a>
                    </ion-menu-toggle>
                    <div class="d-flex justify-content-start align-items-start flex-column gap-1 mt-3">
                        <ion-menu-toggle class="d-none d-lg-block">
                            <a class="menu-link" routerLink="/platform"
                                ><span class="bi bi-shuffle"></span>
                                {{
                                    "Change
                                platform" | translate
                                }}</a
                            >
                        </ion-menu-toggle>
                        <div class="w-100">
                            <div class="menu-link"><span class="bi bi-tags"></span> {{ "All categories" | translate }}</div>
                            <app-header-category-list
                                [categories]="categories"
                                (selectedCategory)="menuSelectCategory($event)"
                            ></app-header-category-list>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <ion-menu-toggle>
                        <a class="menu-link" routerLink="/settings"
                            ><span class="bi bi-gear-wide-connected"></span> {{ "Settings" | translate }}</a
                        >
                    </ion-menu-toggle>
                    <ion-menu-toggle>
                        <a class="menu-link" routerLink="/support"><span class="bi bi-info-circle"></span> {{ "Support" | translate }}</a>
                    </ion-menu-toggle>
                </div>
            </div>
            <ng-template #showMenuSubCategory>
                <div class="w-100 m-x">
                    <div (click)="menuCategoryGoBack()" class="clickable">
                        <span class="bi bi-chevron-left"></span>
                        {{ "Back" | translate }}
                    </div>
                    <div class="pt-3">
                        <div class="menu-link d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start align-items-center">
                                <span class="bi bi-tag"></span>{{ menuCategorySelected.name }}
                            </div>
                            <ion-menu-toggle>
                                <a routerLink="/search" [queryParams]="{ category: menuCategorySelected.id }" class="see-all">{{
                                    "See all" | translate
                                }}</a>
                            </ion-menu-toggle>
                        </div>
                    </div>
                    <app-header-category-list
                        [categories]="childrenCategories"
                        (selectedCategory)="menuSelectCategory($event)"
                    ></app-header-category-list>
                </div>
            </ng-template>
        </div>
    </ion-content>
</ion-menu>

<!-- Main Header (desktop) -->
<div class="container d-none d-lg-block" id="main-desktop-header">
    <!-- Top Header -->
    <div class="d-flex justify-content-center align-items-center w-100">
        <!-- Socials Media -->
        <div class="d-flex gap-2 justify-content-start w-100" id="header-social-media">
            <a href="https://www.facebook.com/avrazya" target="_blank"><i class="ti ti-brand-facebook"></i></a>
            <a href="https://instagram.com/myavrazya" target="_blank"><i class="ti ti-brand-instagram"></i></a>
            <a href="https://www.linkedin.com/company/avrazya" target="_blank"><i class="ti ti-brand-linkedin"></i></a>
            <a href="https://www.youtube.com/@avrazya" target="_blank"><i class="ti ti-brand-youtube"></i></a>
            <a href="https://www.tiktok.com/@avrazya" target="_blank"><i class="ti ti-brand-tiktok"></i></a>
        </div>
        <div class="d-flex gap-3 justify-content-end w-100">
            <a routerLink="/sell-on-avrazya">{{ "Sell on Avrazya" | translate }}</a>
            <!-- <a routerLink="/discounts">{{ 'Discounts' | translate }}</a> -->
            <a routerLink="/contact">{{ "Contact" | translate }}</a>
            <a routerLink="/support">{{ "Support" | translate }}</a>
        </div>
    </div>
    <!-- Logo, Platform Selector, Search Form -->
    <div class="d-flex justify-content-between align-items-center w-100 gap-5">
        <div class="d-flex justify-content-start align-items-center gap-4 w-25">
            <!-- Logo -->
            <div class="d-flex flex-column justify-content-center align-items-start gap-1">
                <a routerLink="/home" class="text-decoration-none" routerDirection="root">
                    <img ngSrc="/assets/avrazya-logo.svg" alt="Avrazya Logo" [width]="150" [height]="45" />
                </a>
                <a routerLink="/platform" class="d-inline-flex gap-1 platform-selector">
                    <i class="ti ti-switch-3"></i> {{ platform | translate }}
                </a>
            </div>
        </div>
        <div class="search-form-container w-50">
            <app-search-form [searchForm]="searchForm"></app-search-form>
        </div>
        <div class="d-flex gap-3 align-items-center justify-content-end w-25">
            <a href="#" (click)="openSettingsModal($event)" type="button">
                <span class="fs-4 bi bi-globe-europe-africa"></span>
            </a>
            <!-- Shopping Cart -->
            <a routerLink="/cart" class="text-decoration-none position-relative">
                <i class="fs-4 ml-2 bi bi-cart4"></i>
                <span class="sc-pill" *ngIf="shoppingCartLineCount > 0">{{ shoppingCartLineCount }}</span>
            </a>
        </div>
    </div>
    <!-- Menu Categories, Home, New Arrivals, Discounts -->
    <div class="d-flex justify-content-center align-items-center w-100">
        <!-- Categories -->
        <div class="d-flex gap-2 justify-content-start w-100" id="header-social-media">
            <div class="d-flex gap-3 justify-content-start px-0 w-100">
                <div>
                    <a href="#" class="ps-0" (click)="openCategoryMenu($event)">
                        <i class="bi bi-list"></i>
                        {{ "All categories" | translate }}
                    </a>
                </div>
                <!--        <div class="horizontal-menu-item"><a routerLink="/home" routerLinkActive="active">{{ 'Home'|translate }}</a></div>-->
                <!-- <div *ngFor="let category of promotedCategories">
                    <a routerLink="/search" [queryParams]="{ category: category.id }"
                        [class.active]="isActive(category)">{{
                        category.name }}</a>
                </div> -->
            </div>
        </div>
        <!-- Bottom Center menu -->
        <div class="d-flex flex-nowrap align-items-center justify-content-center gap-3 w-100">
            <a routerLink="/home">{{ "Home" | translate }}</a>
            <a routerLink="/new-arrivals">{{ "New arrivals" | translate }}</a>
            <a routerLink="/discounts">{{ "Discounts" | translate }}</a>
        </div>
        <!-- Bottom Right menu -->
        <div class="d-flex gap-3 align-items-center justify-content-end gap-3 w-100">
            <a routerLink="/create-offer" class="btn btn-sm btn-outline-danger d-flex align-items-center gap-1"
                ><span class="ti ti-target-arrow"></span> {{ "Publish for free" | translate }}</a
            >
            <a routerLink="/signup" *ngIf="!user">
                {{ "Sign in / Register" | translate }}
            </a>
            <a
                class="mobile-footer-link"
                routerLink="/profile"
                routerLinkActive="active"
                class="d-flex align-items-center gap-2"
                *ngIf="user"
            >
                <span class="bi fs-4 bi-person-circle" *ngIf="!user"></span>
                <span class="bi fs-4 bi-person-check" *ngIf="user && !user.picture"></span>
                <app-picture *ngIf="user && user.picture" [picture]="user.picture" size="25" format="circle"></app-picture>
                {{ "Profile" | translate }}
            </a>
            <ng-container *ngIf="loginLoading">
                <div>
                    <ion-spinner></ion-spinner>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<!-- Mobile Header -->
<div class="d-flex d-lg-none justify-content-between p-2" id="main-header-mobile">
    <div class="d-flex justify-content-start gap-2">
        <a href="#" class="d-flex" (click)="openCategoryMenu($event)">
            <i class="bi bi-list fs-2"></i>
        </a>
        <a routerLink="/home">
            <img ngSrc="/assets/avrazya-logo.svg" alt="Avrazya Logo" [width]="67" [height]="20" />
        </a>
    </div>
    <div class="d-flex justify-content-end align-items-center gap-3 pe-2"></div>
</div>
