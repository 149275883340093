import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { OrderShipment, PostShipment } from '../models/delivery';
import { Order } from '../models/order';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class ShippingService {
    constructor(private readonly apiService: ApiService) {}

    createShipment(order: Order, shipment: PostShipment) {
        return this.apiService
            .post<OrderShipment>('/api/orders/' + order.ref + '/shipments', shipment)
            .pipe(map((shipment) => new OrderShipment(shipment)));
    }

    getShippingInfo(shipmentCode: string) {
        return this.apiService.get<any>('/api/shipments/' + shipmentCode + '/tracker').pipe(map((data) => data));
    }

    shipOrder(order: Order) {
        return this.apiService.post<Order>('/api/orders/' + order.id + '/ship', {}).pipe(map((order) => new Order(order)));
    }
}
