import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Ad } from '../models/ad';
import { Offer } from '../models/offer';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AdService {
    constructor(
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
    ) {}

    getUserAds(user: User): Observable<Ad[]> {
        return this.apiService.get<Offer[]>('/api/users/' + user.id + '/ads').pipe(map((data) => data.map((offer) => new Offer(offer))));
    }
}
