<ion-content>
    <section class="container">
        <h1>{{ 'Finalize your order' | translate }}</h1>
        <p class="text-muted">{{ 'finalize_order.description' | translate }}</p>
        <h2>{{ 'Your contracts' | translate }}</h2>
        <p class="text-muted">{{ 'finalize_order.contrat_description' | translate }}</p>
        <ng-container *ngIf="contracts else contractLoading">
            <p class="text-muted" *ngIf="contracts.length === 0">{{ 'No contracts found' | translate }}</p>
        </ng-container>
        <ng-template #contractLoading>
            <div class="py-3 text-center">
                <app-loader></app-loader>
            </div>
        </ng-template>
        <h2>{{ 'Finalize your order' | translate }}</h2>
        <p class="text-muted">{{ 'finalize_order.bank_details_description' | translate }}</p>
        <ul>
            <li>{{ 'Account Name' | translate }}: {{ 'Avrazya' | translate }}</li>
            <li>{{ 'IBAN' | translate }}:</li>
            <li>{{ 'SWIFT' | translate }}:</li>
            <li>{{ 'Bank Name' | translate }}:</li>
            <li>{{ 'Bank Address' | translate }}:</li>
        </ul>
        <ng-container *ngIf="orders else orderLoading">
            <div *ngFor="let order of orders" class="mb-4 border-bottom pb-4">
                <app-order-summary [order]="order"></app-order-summary>
                <div class="text-center" *ngIf="totalAmountToBePaid" id="total-amount-to-be-paid">
                    <div class="d-flex align-items-center justify-content-center gap-2">
                        {{ 'Total amount to be paid' | translate }} :
                        <app-price [price]="order.totalPriceObject"></app-price>
                    </div>
                    <div class="d-flex align-items-center justify-content-center gap-2">
                        {{ 'Payment reference' | translate }} : {{ order.ref }}
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #orderLoading>
            <div class="py-3 text-center">
                <app-loader></app-loader>
            </div>
        </ng-template>
    </section>
</ion-content>
