import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LoadingController, NavController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { SizeGuide } from '../../../../models/size-guide';
import { SizeGuideService } from '../../../../services/size-guide.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
    selector: 'app-profile-tab-size-guide-list',
    templateUrl: './profile-tab-size-guide-list.component.html',
    styleUrls: ['./profile-tab-size-guide-list.component.scss'],
    imports: [CommonModule, TranslatePipe, LoaderComponent, RouterLink],
})
export class ProfileTabSizeGuideListComponent implements OnInit {
    sizeGuides: SizeGuide[];

    constructor(
        private readonly sizeGuideService: SizeGuideService,
        private readonly navCtrl: NavController,
        private loaderCtrl: LoadingController,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        this.loadSizeGuides();
    }

    async loadSizeGuides() {
        this.sizeGuides = null;
        this.sizeGuideService.list().subscribe({
            next: (sizeGuides) => {
                this.sizeGuides = sizeGuides;
            },
        });
    }

    editSizeGuide(sizeGuide: SizeGuide) {
        this.navCtrl.navigateForward('/profile/size-guide/manage/' + sizeGuide.id);
    }

    async deleteSizeGuide(sizeGuide: SizeGuide) {
        const loader = await this.loaderCtrl.create();
        await loader.present();
        this.sizeGuideService.delete(sizeGuide.id).subscribe({
            next: () => {
                loader.dismiss();
                this.loadSizeGuides();
            },
            error: () => {
                loader.dismiss();
                this.toastService.error(_('Your size guide could not be deleted'));
            },
        });
    }
}
