<ion-content>
    <div class="h-100 p-4 d-flex flex-column justify-content-between" [formGroup]="answerForm">
        <div class="d-flex justify-content-end">
            <span class="bi bi-x fs-3 clickable" (click)="dismiss()"></span>
        </div>
        <h1>{{ "Team account request" | translate }}</h1>
        <div>
            <p [innerHTML]="'Team account request by -user-' | translate: { user: request.userFullname }"></p>
            <select class="form-select" formControlName="position">
                <option [value]="null">{{ "Choose a position" | translate }}</option>
                <option [value]="position.id" *ngFor="let position of positions">{{ position.name | translate }}</option>
            </select>
        </div>
        <div class="d-flex justify-content-evenly mt-4">
            <button class="btn btn-outline-danger" (click)="rejectRequest()">
                <i class="bi bi-x-circle"></i>
                {{ "Reject" | translate }}
            </button>
            <button class="btn btn-outline-primary" (click)="acceptRequest()" [disabled]="answerForm.invalid">
                <i class="bi bi-check-circle"></i>
                {{ "Accept" | translate }}
            </button>
        </div>
    </div>
</ion-content>
