import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { TeamAccountRequest } from '../../models/company';
import { CompanyService } from '../../services/company.service';
import { ToastService } from '../../services/toast.service';
import { CompanyUtils } from '../../utils/company.utils';

@Component({
    selector: 'app-team-account-approve-modal',
    templateUrl: './team-account-approve-modal.component.html',
    styleUrls: ['./team-account-approve-modal.component.scss'],
    imports: [CommonModule, IonContent, TranslatePipe, ReactiveFormsModule],
})
export class TeamAccountApproveModalComponent implements OnInit {
    @Input() request: TeamAccountRequest;
    positions = CompanyUtils.positions;
    answerForm = new FormGroup({
        position: new FormControl(null, Validators.required),
    });

    constructor(
        private readonly companyService: CompanyService,
        private readonly toastService: ToastService,
        private readonly modalCtrl: ModalController,
    ) {}

    ngOnInit() {}

    answer(ans: string): void {
        this.companyService.answerTeamAccountRequest(this.request.id, ans, this.answerForm.value.position).subscribe({
            next: () => {
                this.toastService.success(_('Your answer has been sent'));
            },
            error: (res) => {
                this.toastService.error(res.error.error);
            },
            complete: () => {
                this.modalCtrl.dismiss({ answered: true });
            },
        });
    }

    acceptRequest() {
        if (this.answerForm.invalid) {
            return;
        }
        this.answer('accept');
    }

    rejectRequest() {
        this.answer('reject');
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
