import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Browser } from '@capacitor/browser';
import { AlertController, ModalController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { default as moment } from 'moment';
import { filter, first } from 'rxjs';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { PictureComponent } from 'src/app/components/picture/picture.component';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { CompanyModalComponent } from '../../../../modals/company-modal/company-modal.component';
import { Company } from '../../../../models/company';
import { Document, Media } from '../../../../models/media';
import { User } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';
import { CompanyService } from '../../../../services/company.service';
import { MediaService } from '../../../../services/media.service';
import { TranslateService } from '../../../../services/translate.service';
import { DocumentUtils } from '../../../../utils/document.utils';
import { DocumentExpiresAtValidator } from '../../../../validators/document.validator';

@Component({
    selector: 'app-profile-tab-company',
    templateUrl: './profile-tab-company.component.html',
    styleUrls: ['./profile-tab-company.component.scss'],
    imports: [CommonModule, TranslatePipe, LoaderComponent, FormsModule, ReactiveFormsModule, PictureComponent, ImageUploadComponent],
})
export class ProfileTabCompanyComponent implements OnInit {
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    user: User;
    company: Company;
    cpLoading = false;
    cpProgress = 0;
    loaded = false;
    cshLoading = false;
    cshProgress: number;
    documents: Document[];
    documentTypes = DocumentUtils.DOCUMENT_TYPES;
    addDocumentForm = new FormGroup(
        {
            type: new FormControl('', [Validators.required]),
            expiresAt: new FormControl(''),
        },
        [DocumentExpiresAtValidator],
    );

    constructor(
        private readonly authService: AuthService,
        private readonly modalCtrl: ModalController,
        private readonly mediaService: MediaService,
        private readonly companyService: CompanyService,
        private readonly alertCtrl: AlertController,
        private readonly translateService: TranslateService,
    ) {}

    ngOnInit() {
        this.authService.userLoggedIn
            .pipe(filter((loggedIn) => !!loggedIn))
            .pipe(first())
            .subscribe({
                next: (user) => {
                    this.user = user;
                    this.companyService.getMyCompany(user.id).subscribe({
                        next: (company) => {
                            this.company = company;
                            this.loaded = true;
                            this.refreshDocuments();
                        },
                    });
                },
            });
    }

    private refreshDocuments() {
        this.documents = null;
        // Load documents
        this.mediaService.getDocuments(this.company?.id).subscribe({
            next: (documents) => {
                this.documents = documents;
                // Filter out documents depending on company activity and if present or not in document list
                this.documentTypes = DocumentUtils.DOCUMENT_TYPES.filter((d) => {
                    // If already in the list
                    if (this.documents.find((doc) => doc.type === d.value)) {
                        return false;
                    }

                    // Check activity
                    return !d.companyActivities || d.companyActivities.includes(this.company.activity);
                });
            },
        });
    }

    private sendCompanyPicture(media: Media) {
        this.companyService.update(this.company.id, { picture: media.id }).subscribe({
            next: (company) => {
                this.company.picture = company.picture;
                this.cpProgress = 100;
                this.cpLoading = false;
            },
            error: (err) => {
                this.cpLoading = false;
                console.error(err);
            },
        });
    }

    private sendCompanySearchHeader(media: Media) {
        this.companyService.update(this.company.id, { searchHeader: media.id }).subscribe({
            next: (company) => {
                this.company.searchHeader = company.searchHeader;
                this.cshProgress = 100;
                this.cshLoading = false;
            },
            error: (err) => {
                this.cshLoading = false;
                console.error(err);
            },
        });
    }

    changeCompanyPicture() {
        this.mediaService
            .pickImage()
            .then((file) => {
                this.cpProgress = 0;
                this.cpLoading = true;
                this.mediaService.upload(
                    file.blob,
                    file.filename,
                    'avatar_company',
                    (progress) => {
                        this.cpProgress = progress;
                    },
                    (media) => {
                        this.sendCompanyPicture(media);
                    },
                );
            })
            .catch(() => {});
    }

    changeCompanySearchHeader() {
        this.mediaService
            .pickImage()
            .then((file) => {
                this.cshProgress = 0;
                this.cshLoading = true;
                this.mediaService.upload(
                    file.blob,
                    file.filename,
                    'search_header_company',
                    (progress) => {
                        this.cshProgress = progress;
                    },
                    (media) => {
                        this.sendCompanySearchHeader(media);
                    },
                );
            })
            .catch(() => {});
    }

    async changeCompanyInfo() {
        const modal = await this.modalCtrl.create({
            component: CompanyModalComponent,
            componentProps: {
                company: this.company,
            },
        });
        modal.present();
        modal.onWillDismiss().then(() => {
            this.companyService.getMyCompany(this.user.id).subscribe({
                next: (company) => {
                    this.company = company;
                    this.refreshDocuments();
                },
            });
        });
    }

    displayExpiresAtField() {
        return DocumentUtils.requiresExpirationDate(this.addDocumentForm.get('type').value);
    }

    async pickDocument() {
        if (this.addDocumentForm.invalid) {
            return;
        }
        console.log('pick document');
        // Pick document
        this.fileInput.nativeElement.click();
    }

    canAddDocuments() {
        return this.company && this.documents && this.documentTypes.length > 0;
    }

    async uploadDocument() {
        const media = await this.mediaService.pickDocument('document');
        if (!media) {
            return;
        }

        // Add document
        const type = this.addDocumentForm.get('type').value;
        let expiresAt = DocumentUtils.requiresExpirationDate(type) ? this.addDocumentForm.get('expiresAt').value : null;
        if (expiresAt) {
            // Use moment to format date as end of day
            expiresAt = moment(expiresAt).endOf('day').format();
        }
        this.companyService.addDocument({ type, expiresAt, media: media.id }).subscribe({
            next: () => {
                LoadingUtils.hide();
                this.refreshDocuments();
                this.addDocumentForm.reset();
            },
        });
    }

    async downloadDocument(d: Document) {
        await LoadingUtils.show();

        this.mediaService.createDownload(d.media.id).subscribe({
            next: (download) => {
                LoadingUtils.hide();
                Browser.open({ url: download.url, windowName: '_system' });
            },
        });
    }

    getLabelFromType(type: string) {
        return DocumentUtils.getLabelFromType(type);
    }

    async deleteDocument(d: Document) {
        const alert = await this.alertCtrl.create({
            header: this.translateService.get(_('Delete document')),
            message: this.translateService.get(_('Are you sure you want to delete this document?')),
            buttons: [
                {
                    text: this.translateService.get(_('Cancel')),
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: this.translateService.get(_('Delete')),
                    handler: async () => {
                        await LoadingUtils.show();
                        this.companyService.deleteDocument(d.id).subscribe({
                            next: () => {
                                this.refreshDocuments();
                                LoadingUtils.hide();
                            },
                        });
                    },
                },
            ],
        });
        alert.present();
    }
}
