<ng-container *ngIf="!loading; else displayLoader">
    <ng-container *ngIf="user">
        <ng-container *ngIf="user.type === 'pro'; else notPro">
            <div class="row align-items-stretch g-4">
                <div class="col-12 col-md-4">
                    <div class="card p-3">
                        <h3>{{ "Shipping Address" | translate }}</h3>
                        <div class="pt-2">
                            <ng-container *ngIf="!addresses.shipping; else shippingAddress">
                                {{ "No address" | translate }}
                            </ng-container>
                            <ng-template #shippingAddress>
                                <app-address [address]="addresses.shipping"></app-address>
                            </ng-template>
                        </div>
                        <div class="text-right mt-auto">
                            <button type="button" class="btn btn-outline-primary" (click)="selectAddress('shipping')">
                                {{ "Edit" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card p-3">
                        <h3>{{ "Return Address" | translate }}</h3>
                        <div class="pt-2">
                            <ng-container *ngIf="!addresses.return; else returnAddress">
                                {{ "No address" | translate }}
                            </ng-container>
                            <ng-template #returnAddress>
                                <app-address [address]="addresses.return"></app-address>
                            </ng-template>
                        </div>
                        <div class="text-right mt-auto">
                            <button type="button" class="btn btn-outline-primary" (click)="selectAddress('return')">
                                {{ "Edit" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card p-3">
                        <h3>{{ "Billing Address" | translate }}</h3>
                        <div class="pt-2">
                            <ng-container *ngIf="!addresses.billing; else billingAddress">
                                {{ "No address" | translate }}
                            </ng-container>
                            <ng-template #billingAddress>
                                <app-address [address]="addresses.billing"></app-address>
                            </ng-template>
                        </div>
                        <div class="text-right mt-auto">
                            <button type="button" class="btn btn-outline-primary" (click)="selectAddress('billing')">
                                {{ "Edit" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #notPro>
            <h3>{{ "My addresses" | translate }}</h3>
            <div class="pt-2">
                <app-address-list [isProfile]="true"></app-address-list>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
<ng-template #displayLoader>
    <div class="text-center py-4">
        <app-loader></app-loader>
    </div>
</ng-template>
