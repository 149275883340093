<ng-container *ngIf="tracking || notFound; else loadingOrder">
    <div class="d-flex justify-content-between">
        <a (click)="back()" class="fs-4 clickable"><i class="bi bi-arrow-left"></i></a>
    </div>
    <ng-container *ngIf="notFound; else displayInfos">
        <div class="text-center py-4">
            <h4>{{ "No tracking found" | translate }}</h4>
        </div>
    </ng-container>
    <ng-template #displayInfos>
        <div class="row">
            <div class="col-12 col-lg-4">
                <div class="card p-3 d-flex justify-content-start flex-column gap-3">
                    <div>
                        <span class="fw-bold">{{ "Code" | translate }}</span
                        ><br />
                        {{ code }}
                    </div>
                    <div>
                        <span class="fw-bold">{{ "PTT tracking number" | translate }}</span
                        ><br />
                        {{ tracking.barcode }}
                    </div>
                    <div>
                        <span class="fw-bold">{{ "Shipment status" | translate }}</span
                        ><br />
                        {{ tracking.status }}
                    </div>
                    <div>
                        <span class="fw-bold">{{ "Destination" | translate }}</span
                        ><br />
                        {{ tracking.deliveryLocation }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8">
                <div class="card p-3 mb-3">
                    <h4>{{ "History" | translate }}</h4>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{ "Location" | translate }}</th>
                                <th>{{ "Shipment status" | translate }}</th>
                                <th>{{ "Date" | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let history of tracking.history">
                                <td>{{ history.location }}</td>
                                <td>{{ history.status }}</td>
                                <td>{{ history.date | date: "dd.MM.yyyy HH:mm" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-template #loadingOrder>
    <div class="text-center py-4">
        <app-loader></app-loader>
    </div>
</ng-template>
