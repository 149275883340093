import { Routes } from '@angular/router';
import { ProfileTabAccountSecurityComponent } from './profile-tabs/profile-tab-account-security/profile-tab-account-security.component';
import { ProfileTabAddressesComponent } from './profile-tabs/profile-tab-addresses/profile-tab-addresses.component';
import { ProfileTabAdsPage } from './profile-tabs/profile-tab-ads/profile-tab-ads.page';
import { ProfileTabCompanyComponent } from './profile-tabs/profile-tab-company/profile-tab-company.component';
import { ProfileTabOffersPage } from './profile-tabs/profile-tab-offers/profile-tab-offers.page';
import { ProfileTabOrderShipmentTrackerComponent } from './profile-tabs/profile-tab-order-shipment-tracker/profile-tab-order-shipment-tracker.component';
import { ProfileTabOrderShipmentComponent } from './profile-tabs/profile-tab-order-shipment/profile-tab-order-shipment.component';
import { ProfileTabOrderComponent } from './profile-tabs/profile-tab-order/profile-tab-order.component';
import { ProfileTabPersonalInfoComponent } from './profile-tabs/profile-tab-personal-info/profile-tab-personal-info.component';
import { ProfileTabProfilePage } from './profile-tabs/profile-tab-profile/profile-tab-profile.page';
import { ProfileTabPurchasesComponent } from './profile-tabs/profile-tab-purchases/profile-tab-purchases.component';
import { ProfileTabSalesComponent } from './profile-tabs/profile-tab-sales/profile-tab-sales.component';
import { ProfileTabSizeGuideListComponent } from './profile-tabs/profile-tab-size-guide-list/profile-tab-size-guide-list.component';
import { ProfileTabSizeGuideComponent } from './profile-tabs/profile-tab-size-guide/profile-tab-size-guide.component';
import { ProfileTabStatsPage } from './profile-tabs/profile-tab-stats/profile-tab-stats.page';
import { ProfileTabTeamPage } from './profile-tabs/profile-tab-team/profile-tab-team.page';
import { ProfileTabWalletComponent } from './profile-tabs/profile-tab-wallet/profile-tab-wallet.component';

export const profileRoutes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: ProfileTabProfilePage },
    { path: 'ads', component: ProfileTabAdsPage },
    { path: 'offers', component: ProfileTabOffersPage },
    { path: 'stats', component: ProfileTabStatsPage },
    { path: 'team', component: ProfileTabTeamPage },
    { path: 'personal-info', component: ProfileTabPersonalInfoComponent },
    { path: 'account-security', component: ProfileTabAccountSecurityComponent },
    { path: 'sales', component: ProfileTabSalesComponent },
    { path: 'purchases', component: ProfileTabPurchasesComponent },
    { path: 'addresses', component: ProfileTabAddressesComponent },
    { path: 'orders/:ref', component: ProfileTabOrderComponent },
    { path: 'orders/:ref/shipments', component: ProfileTabOrderShipmentComponent },
    { path: 'orders/:ref/shipments/:code', component: ProfileTabOrderShipmentTrackerComponent },
    { path: 'company', component: ProfileTabCompanyComponent },
    { path: 'wallet', component: ProfileTabWalletComponent },
    { path: 'size-guide/manage', component: ProfileTabSizeGuideComponent },
    { path: 'size-guide/manage/:id', component: ProfileTabSizeGuideComponent },
    { path: 'size-guide', component: ProfileTabSizeGuideListComponent },
];
