<div class="row g-4">
    <div class="col-12 col-lg-8">
        <div class="card p-4">
            <h2>{{ "Your cards" | translate }}</h2>
            <p>
                {{ "Your cards will be visible here" | translate }}
            </p>
            <ng-container *ngIf="cards; else cardLoader">
                <div class="card p-3 mb-2" *ngFor="let card of cards">
                    <div class="row">
                        <div class="col-8 d-flex align-items-center justify-content-start gap-3">
                            <span class="bi bi-credit-card-2-front"></span>
                            <strong>{{ card.brandFormatted }}</strong>
                            <span class="font-monospace fs-6">•••• •••• •••• {{ card.digits }}</span>
                            <span class="text-muted">{{ card.expiresAt | date: "MM/yy" }}</span>
                        </div>
                        <div class="col-4 text-right">
                            <a class="text-danger clickable" (click)="deleteCard(card)">
                                <span class="bi bi-trash"></span>
                            </a>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="cards.length === 0">
                    <div class="py-4 text-center">
                        <em>{{ "You have no cards yet" | translate }}</em>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="card p-4 mt-4">
            <h2>{{ "Your iban" | translate }}</h2>
            <p>
                {{ "Your iban description" | translate }}
            </p>
            <ng-container *ngIf="loadingIban; else ibanContainer">
                <div class="py-4 text-center">
                    <app-loader></app-loader>
                </div>
            </ng-container>
            <ng-template #ibanContainer>
                <label for="iban">{{ "Iban" | translate }}</label>
                <div class="d-flex align-items-center gap-3 w-100">
                    <div class="w-100" [formGroup]="ibanForm">
                        <input
                            type="text"
                            class="form-control"
                            id="iban"
                            formControlName="iban"
                            [ngClass]="{ 'is-invalid': ibanForm.get('iban').invalid && ibanForm.get('iban').touched }"
                            placeholder="{{ 'Iban example' | translate }}"
                        />
                    </div>
                    <div class="text-right flex-grow-1">
                        <button class="btn btn-primary text-nowrap" (click)="updateIban()">
                            <span class="bi bi-pencil me-1"></span>
                            {{ "Change iban" | translate }}
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template #cardLoader>
                <div class="py-4 text-center">
                    <app-loader></app-loader>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="col-12 col-lg-4">
        <div class="card p-4">
            <h2>{{ "Wallet" | translate }}</h2>
            <p>
                {{ "Your wallet balance explained" | translate }}
            </p>
            <div class="d-flex justify-content-between mt-4">
                <strong>
                    {{ "Balance" | translate }}
                </strong>
                <app-price [price]="getWalletBalance()" [alwaysShowPrice]="true"></app-price>
            </div>
        </div>
    </div>
</div>
