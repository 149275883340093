import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    imports: [CommonModule, LoaderComponent],
})
export class ImageUploadComponent implements OnInit {
    @Input() loading: boolean = true;
    @Input() progress = 0;
    @Input() set size(size: string) {
        const sizes = size.split('x');
        this.width = sizes[0] + 'px';
        if (sizes.length === 1) {
            this.height = sizes[0] + 'px'; // Square
        } else {
            this.height = sizes[1] + 'px';
        }
    }
    width: string;
    height: string;

    constructor() {}

    ngOnInit() {}
}
