<div *ngIf="user">
    <div class="card p-4">
        <h2 class="d-flex m-0 align-items-center">{{ "Account security" | translate }}</h2>
        <div class="row mt-3 align-items-center">
            <div class="col-12 col-md-6 form-group">
                <label class="required">{{ "Current password" | translate }}</label>
                <input type="password" value="********" disabled class="form-control" />
            </div>
            <div class="col-12 col-md-6 form-group text-center">
                <button class="btn btn-link" type="button" (click)="changePassword()">
                    <span>{{ "Change your password" | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
