<div class="card p-4">
    <div class="table-responsive">
        <div class="d-flex justify-content-between">
            <h2>{{ "Size guides" | translate }}</h2>
            <a class="btn btn-primary" routerLink="/profile/size-guide/manage">{{ "Add size guide" | translate }}</a>
        </div>
        <ng-container *ngIf="sizeGuides; else loading">
            <table class="table" *ngIf="sizeGuides && sizeGuides.length > 0">
                <thead>
                    <tr>
                        <th>{{ "Size guide name" | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sizeGuide of sizeGuides">
                        <td class="align-middle">{{ sizeGuide.name }}</td>
                        <td class="d-flex justify-content-end flex-nowrap gap-2">
                            <button class="btn btn-link-primary" (click)="editSizeGuide(sizeGuide)"><i class="bi bi-pencil"></i></button>
                            <button class="btn btn-link-danger" (click)="deleteSizeGuide(sizeGuide)"><i class="bi bi-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-template #loading>
            <div class="py-4 text-center">
                <app-loader></app-loader>
            </div>
        </ng-template>
        <ng-container *ngIf="sizeGuides && sizeGuides.length === 0">
            <div class="py-4 text-center">
                <em>{{ "No size guides found" | translate }}</em>
            </div>
        </ng-container>
    </div>
</div>
