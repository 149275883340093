<ng-container *ngIf="order; else loadingOrder">
    <div class="d-flex justify-content-between">
        <a (click)="back()" class="fs-4 clickable"><i class="bi bi-arrow-left"></i></a>
    </div>
    <div class="actions d-flex justify-content-between align-items-center gap-3 py-3">
        <div>
            <h3 class="m-0 p-0">{{ "Order ref" | translate: { ref: order.ref } }}</h3>
            <p class="p-0 m-0">{{ order.createdAt | date: "short" }}</p>
        </div>
        <div class="d-flex gap-3">
            <a
                class="btn btn-link"
                routerLink="/checkout/wholesale-payment-success"
                [queryParams]="{ orders: order.ref }"
                *ngIf="order.isWholesale && order.isPendingStatus"
            >
                <i class="bi bi-check-circle clickable me-2"></i>
                {{ "Finalize order" | translate }}
            </a>
            <a class="btn btn-link" (click)="redirectToContract()">
                <i class="bi bi-file-earmark-text clickable me-2"></i>
                {{ "Download contract" | translate }}
            </a>
            <a class="btn btn-link" (click)="shipOrder()" *ngIf="isSeller && order.isShippable">
                <i class="bi bi-truck clickable me-2"></i>
                {{ "Ship order" | translate }}
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-8">
            <div class="card p-3">
                <div class="row g-3 justify-content-center">
                    <div class="col-12 col-md-4">
                        <h5>{{ "Delivery Address" | translate }}</h5>
                        {{ order.deliveryAddress.mobilePhone }}<br />
                        {{ order.deliveryAddress.companyName }}<br />
                        {{ order.deliveryAddress.streetNumber }} {{ order.deliveryAddress.street }}<br />
                        {{ order.deliveryAddress.zip }} {{ order.deliveryAddress.city }}<br />
                        {{ order.deliveryAddress.region }} {{ order.deliveryAddress.country }}
                    </div>
                    <div class="col-12 col-md-4 offset-md-2">
                        <h5>{{ "Billing Address" | translate }}</h5>
                        {{ order.deliveryAddress.mobilePhone }}<br />
                        {{ order.deliveryAddress.companyName }}<br />
                        {{ order.billingAddress.streetNumber }} {{ order.billingAddress.street }}<br />
                        {{ order.billingAddress.zip }} {{ order.billingAddress.city }}<br />
                        {{ order.billingAddress.region }} {{ order.billingAddress.country }}
                    </div>
                </div>
            </div>
            <div class="py-3">
                <app-order-summary [order]="order" [showDetailButton]="false" [showTitle]="false"></app-order-summary>
            </div>
        </div>
        <!-- Transactions -->
        <div class="col-12 col-lg-4">
            <div class="card p-3 mb-3">
                <h4>{{ "Transactions" | translate }}</h4>
                <table class="table" *ngIf="order.transactions.length > 0; else noTransactions">
                    <thead>
                        <tr>
                            <th>{{ "Date" | translate }}</th>
                            <th>{{ "Amount" | translate }}</th>
                            <th>{{ "Payment status" | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let transaction of order.transactions">
                            <td>{{ transaction.createdAt | date: "short" }}</td>
                            <td><app-price [price]="transaction.amountPriceObject"></app-price></td>
                            <td [class]="transaction.statusColor" class="fw-bold">{{ transaction.statusFormatted | translate }}</td>
                        </tr>
                    </tbody>
                </table>
                <ng-template #noTransactions>
                    <div class="text-center py-4 text-warning">{{ "Pending payment" | translate }}</div>
                </ng-template>
            </div>
            <!-- Invoice -->
            <div class="card p-3 mb-3">
                <h4 *ngIf="isSeller && !order?.invoice">{{ "Add your invoice" | translate }}</h4>
                <ng-container *ngIf="order?.invoice; else noInvoice">
                    <div class="d-flex justify-content-between align-items-center">
                        <h4 class="m-0 p-0">
                            {{ "Invoice -number-" | translate: { number: order?.invoice?.number } }}
                        </h4>
                        <span class="text-right"><i class="bi bi-cloud-download clickable fs-3" (click)="downloadInvoice()"></i></span>
                    </div>
                </ng-container>
                <ng-template #noInvoice>
                    <h4 *ngIf="!isSeller">{{ "Invoice" | translate }}</h4>
                    <ng-container *ngIf="!isSeller">
                        <div class="text-center py-4">
                            <em>{{ "No invoice yet" | translate }}</em>
                        </div>
                    </ng-container>
                    <div *ngIf="isSeller">
                        <div class="form-group">
                            <div class="d-flex gap-3">
                                <button class="btn btn-primary" *ngIf="isSeller" (click)="createInvoice()" type="button">
                                    {{ "Pick file" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <!-- Shipments -->
            <div class="card p-3 mb-3" *ngIf="!order.isWholesale">
                <h4>{{ "Shipments" | translate }}</h4>
                <table class="table" *ngIf="order.shipments.length > 0; else noShipments">
                    <thead>
                        <tr>
                            <th>{{ "Provider" | translate }}</th>
                            <th>{{ "Tracking Number" | translate }}</th>
                            <th>{{ "Shipment status" | translate }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let shipment of order.shipments">
                            <td>{{ shipment.provider.toUpperCase() }}</td>
                            <td>{{ shipment.code }}</td>
                            <td [class]="shipment.statusColor" class="fw-bold">{{ shipment.statusFormatted | translate }}</td>
                            <td>
                                <a [routerLink]="'/profile/orders/' + order.ref + '/shipments/' + shipment.code"
                                    ><i class="bi bi-truck"></i
                                ></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ng-template #noShipments>
                    <div class="text-center py-4">
                        <p>{{ "No shipments" | translate }}</p>
                        <a class="btn btn-outline-primary" [routerLink]="'/profile/orders/' + order.ref + '/shipments'" *ngIf="isSeller">
                            {{ "Create shipment sticker" | translate }}
                        </a>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loadingOrder>
    <div class="text-center py-4">
        <app-loader></app-loader>
    </div>
</ng-template>
