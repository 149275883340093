<div class="card p-3">
    <div class="d-flex justify-content-between pb-3">
        <div>
            <ng-container *ngIf="showTitle">
                <h4 class="m-0 p-0">{{ "Order ref" | translate: { ref: order.ref } }}</h4>
                <div>{{ order.createdAt | date: "short" }}</div>
            </ng-container>
        </div>
        <div class="d-flex justify-content-end align-items-center gap-3">
            <span class="fw-bold fs-4" [class]="order.statusColor">{{ order.statusFormatted | translate }}</span>
            <ng-container *ngIf="showDetailButton">
                <a class="btn btn-outline-primary" [routerLink]="'/profile/orders/' + order.ref">{{ "Details" | translate }}</a>
            </ng-container>
        </div>
    </div>
    <div class="d-flex gap-2 flex-column">
        <div class="separator"></div>

        <ng-container *ngFor="let item of order.items">
            <div class="d-flex justify-content-start align-items-stretch gap-4">
                <app-picture [picture]="item.offer.medias[0]" size="75"></app-picture>
                <div class="d-flex justify-content-between flex-column align-items-start">
                    <div>
                        <h5 class="m-0 p-0">
                            <a [routerLink]="['/offers', item.offer.id + '-' + item.offer.slug]">{{ item.offer.name }}</a>
                        </h5>
                        <p class="text-muted m-0 p-0">
                            <app-variant-name [variant]="item.variant"></app-variant-name>
                        </p>
                    </div>
                    <p class="p-0 m-0">
                        <span class="fw-bold">{{ "Quantity" | translate }}:</span>&nbsp;&nbsp;<span>{{ item.quantity }}</span>
                    </p>
                </div>
                <div class="ms-auto d-flex justify-content-end align-items-center">
                    <app-price [price]="item.totalPriceObject" [convert]="false"></app-price>
                </div>
            </div>
            <div class="separator"></div>
        </ng-container>
    </div>
    <div class="d-flex justify-content-end align-items-end mt-3 gap-1 flex-column">
        <table>
            <tr>
                <td class="text-right pe-3">{{ "Total without taxes" | translate }}</td>
                <td class="text-right"><app-price [price]="order.totalWithoutTaxesPriceObject" [convert]="false"></app-price></td>
            </tr>
            <ng-container *ngFor="let tax of order.taxes">
                <tr>
                    <td class="text-right pe-3">{{ "VAT -rate-" | translate: { rate: tax.rate / 100 } }}</td>
                    <td class="text-right"><app-price [price]="tax.amountPriceObject" [convert]="false"></app-price></td>
                </tr>
            </ng-container>
            <tr>
                <td class="text-right pe-3">{{ "Delivery" | translate }}</td>
                <td class="text-right"><app-price [price]="order.deliveryTotalPriceObject" [convert]="false"></app-price></td>
            </tr>
            <tr>
                <td class="fw-bold fs-4 text-right pe-3">{{ "Total" | translate }}</td>
                <td class="fw-bold fs-4"><app-price [price]="order.totalPriceObject" [convert]="false"></app-price></td>
            </tr>
        </table>
    </div>
</div>
