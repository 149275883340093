<div class="card p-4">
    <ng-container *ngIf="!orders; else orderList">
        <div class="text-center">
            <app-loader></app-loader>
        </div>
    </ng-container>
    <ng-template #orderList>
        <ng-container *ngIf="orders.length === 0">
            <div class="py-4 text-center">
                {{ "No orders found" | translate }}
            </div>
        </ng-container>
        <div class="table-responsive">
            <table class="table mw-500" *ngIf="orders.length > 0">
                <thead>
                    <tr>
                        <th>{{ "Date" | translate }}</th>
                        <th>{{ "Order number" | translate }}</th>
                        <th>{{ "Client" | translate }}</th>
                        <th>{{ "Order status" | translate }}</th>
                        <th>{{ "Price" | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of orders">
                        <td>{{ order.createdAt | date: "dd.MM.yyyy" }}</td>
                        <td>{{ order.ref }}</td>
                        <td>{{ order.customer.fullname }}</td>
                        <td class="order-status" [class]="order.statusColor" [innerHTML]="order.statusFormatted | translate"></td>
                        <td><app-price [price]="order.totalPriceObject"></app-price></td>
                        <td class="text-right">
                            <a class="fs-5" [routerLink]="['/profile/orders', order.ref]">
                                <i class="bi bi-eye"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-template>
</div>
