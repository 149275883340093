import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { OfferVariant } from '../../models/offer-definition';
import { OfferService } from '../../services/offer.service';

@Component({
    selector: 'app-variant-name',
    templateUrl: './variant-name.component.html',
    styleUrls: ['./variant-name.component.scss'],
    imports: [CommonModule],
})
export class VariantNameComponent implements OnInit {
    @Input() variant: OfferVariant;
    names: { id: number; name: string; value: string }[];

    constructor(private readonly offerService: OfferService) {}

    ngOnInit() {
        const data: { [key: number]: string } = {};
        for (const definition of this.variant.definitions) {
            data[definition.filter] = definition.value;
        }
        this.offerService.getVariantName(data).subscribe({
            next: (names) => {
                this.names = names;
            },
        });
    }
}
