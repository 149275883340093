import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { Category } from '../../models/category';
import { CategoryService } from '../../services/category.service';

@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss'],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, TranslatePipe],
})
export class SearchFormComponent implements OnInit {
    categories: Category[] = [];
    @Input() searchForm: FormGroup;

    constructor(
        private readonly navCtrl: NavController,
        private readonly categoryService: CategoryService,
    ) {}

    ngOnInit() {
        if (!this.searchForm) {
            this.searchForm = new FormGroup({
                category: new FormControl('', Validators.required),
                region: new FormControl(''),
                country: new FormControl(''),
                query: new FormControl(''),
            });
        }
        this.categoryService.categories.pipe(filter((categories) => !!categories)).subscribe((categories) => {
            this.categories = categories;
        });
    }

    search() {
        this.navCtrl.navigateForward('/search', {
            queryParams: this.searchForm.value,
        });
    }
}
