import { AbstractControl } from '@angular/forms';
import { DocumentUtils } from '../utils/document.utils';

export function DocumentExpiresAtValidator(control: AbstractControl) {
    const value = control.value;
    if (!value) {
        return null;
    }

    // Depending on the document type, the expiration date is mandatory or not
    if (DocumentUtils.requiresExpirationDate(value.type) && !value.expiresAt) {
        control.get('expiresAt').setErrors({ required: true });
        return { expiresAtRequired: true };
    } else {
        // Remove error expiresAtRequired if it exists
        const errors = control.errors;
        if (errors && errors['expiresAtRequired']) {
            delete errors['expiresAtRequired'];
            control.setErrors(errors);
        }
    }

    return null;
}
