import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { filter, forkJoin, take } from 'rxjs';
import { DeciHelperComponent } from 'src/app/components/deci-helper/deci-helper.component';
import { PhoneNumberFormComponent } from 'src/app/components/phone-number-form/phone-number-form.component';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { PostShipment } from '../../../../models/delivery';
import { Order } from '../../../../models/order';
import { AuthService } from '../../../../services/auth.service';
import { CompanyService } from '../../../../services/company.service';
import { OrderService } from '../../../../services/order.service';
import { ShippingService } from '../../../../services/shipping.service';
import { ToastService } from '../../../../services/toast.service';
import { RegexUtils } from '../../../../utils/regex.utils';

@Component({
    selector: 'app-profile-tab-order-shipment',
    templateUrl: './profile-tab-order-shipment.component.html',
    styleUrls: ['./profile-tab-order-shipment.component.scss'],
    imports: [CommonModule, TranslatePipe, FormsModule, ReactiveFormsModule, PhoneNumberFormComponent, DeciHelperComponent],
})
export class ProfileTabOrderShipmentComponent implements OnInit {
    order: Order;
    form: FormGroup;

    constructor(
        private readonly orderService: OrderService,
        private readonly route: ActivatedRoute,
        private readonly navCtrl: NavController,
        private readonly toastService: ToastService,
        private readonly shippingService: ShippingService,
        private readonly authService: AuthService,
        private readonly companyService: CompanyService,
    ) {}

    ngOnInit() {
        // const user = this.authService.userLoggedIn.value;
        const ref = this.route.snapshot.paramMap.get('ref');
        forkJoin({
            user: this.authService.userLoggedIn.pipe(
                filter((user) => !!user),
                take(1),
            ),
            order: this.orderService.getOrder(ref),
        }).subscribe({
            next: ({ order, user }) => {
                this.order = order;
                // init form
                this.initForm();
                // user is pro and has a company
                if (user.type === 'pro') {
                    this.companyService.getAddresses(user.company).subscribe({
                        next: (addresses) => {
                            if (addresses.return) {
                                const address = addresses.return;
                                this.form.get('return').patchValue({
                                    fullname: user.fullname,
                                    email: user.email,
                                    phone: address.mobilePhone,
                                    street: address.streetFormatted,
                                    region: address.region,
                                    city: address.city,
                                    zip: address.zip,
                                });
                            }
                        },
                    });
                }
            },
        });
    }

    async initForm() {
        this.form = new FormGroup({
            shipping: new FormGroup({
                fullname: new FormControl(this.order.customer?.fullname, [Validators.required]),
                email: new FormControl(this.order.customer?.email, [Validators.required]),
                phone: new FormControl(this.order.customer?.mobilePhone, [Validators.required]),
                street: new FormControl(this.order?.deliveryAddress?.streetFormatted, [Validators.required]),
                region: new FormControl(this.order.deliveryAddress?.region, [Validators.required]),
                city: new FormControl(this.order.deliveryAddress?.city, [Validators.required]),
                zip: new FormControl(this.order.deliveryAddress?.zip, [Validators.required]),
            }),
            return: new FormGroup({
                fullname: new FormControl('', [Validators.required]),
                email: new FormControl('', [Validators.required]),
                phone: new FormControl('', [Validators.required]),
                street: new FormControl('', [Validators.required]),
                region: new FormControl('', [Validators.required]),
                city: new FormControl('', [Validators.required]),
                zip: new FormControl('', [Validators.required]),
            }),
            shipments: new FormArray([], [Validators.required, Validators.minLength(1)]),
        });
    }

    cancel() {
        this.navCtrl.back();
    }

    async submit() {
        const shipment: PostShipment = this.form.value;
        await LoadingUtils.show();
        this.shippingService.createShipment(this.order, shipment).subscribe({
            next: () => {
                this.navCtrl.back();
                LoadingUtils.hide();
                this.toastService.success(_('Shipment created'));
            },
        });
    }

    addShipmentForm() {
        (this.form.get('shipments') as FormArray).push(
            new FormGroup({
                weight: new FormControl('', [Validators.required, Validators.pattern(RegexUtils.integer)]),
                deci: new FormControl('', [Validators.required, Validators.pattern(RegexUtils.decimal)]),
            }),
        );
    }

    removeShipmentForm(index: number) {
        (this.form.get('shipments') as FormArray).removeAt(index);
    }

    getShipmentForms(): FormGroup[] {
        return (this.form.get('shipments') as FormArray).controls as FormGroup[];
    }
}
