<ion-content>
    <form class="p-4" [formGroup]="form" *ngIf="form">
        <div class="d-flex justify-content-end">
            <span class="bi bi-x fs-3 clickable" (click)="cancel()"></span>
        </div>
        <h1 class="mt-0 mb-3">{{ "Update offer" | translate }}</h1>
        <!-- Name -->
        <div class="form-group">
            <label for="name">{{ "Offer title" | translate }}</label>
            <input
                type="text"
                class="form-control"
                id="name"
                formControlName="name"
                [class.is-invalid]="form.get('name').invalid && form.get('name').touched"
            />
        </div>
        <!-- Description -->
        <div class="form-group">
            <label for="description">{{ "Description" | translate }}</label>
            <textarea
                class="form-control"
                id="description"
                formControlName="description"
                [class.is-invalid]="form.get('description').invalid && form.get('description').touched"
            ></textarea>
        </div>
        <!-- Reference price -->
        <div class="form-group">
            <label for="referencePrice">{{ "Price" | translate }}</label>
            <input
                type="number"
                class="form-control"
                id="referencePrice"
                formControlName="referencePrice"
                [class.is-invalid]="form.get('referencePrice').invalid && form.get('referencePrice').touched"
            />
        </div>
        <!-- Reference currency -->
        <div class="form-group">
            <label for="referenceCurrency">{{ "Currency" | translate }}</label>
            <app-select
                [options]="currencies"
                formControlName="referenceCurrency"
                id="referenceCurrency"
                [multiple]="false"
                [isInvalid]="form.get('referenceCurrency').invalid && form.get('referenceCurrency').touched"
            ></app-select>
        </div>
        <!-- Days to ship -->
        <div class="form-group" *ngIf="form.contains('daysToShip')">
            <label for="daysToShip">{{ "Days to ship" | translate }}</label>
            <input
                type="number"
                class="form-control"
                id="daysToShip"
                formControlName="daysToShip"
                [class.is-invalid]="form.get('daysToShip').invalid && form.get('daysToShip').touched"
            />
        </div>
        <!-- Stocks -->
        <div class="form-group" *ngIf="form.contains('stocks')">
            <label for="stocks">{{ "Stocks" | translate }}</label>
            <input
                type="number"
                class="form-control"
                id="stocks"
                formControlName="stocks"
                [class.is-invalid]="form.get('stocks').invalid && form.get('stocks').touched"
            />
        </div>
        <!-- sku -->
        <div class="form-group" *ngIf="form.contains('sku')">
            <label for="sku">{{ "SKU" | translate }}</label>
            <input
                type="text"
                class="form-control"
                id="sku"
                formControlName="sku"
                [class.is-invalid]="form.get('sku').invalid && form.get('sku').touched"
            />
        </div>
        <ng-container *ngIf="variants.length > 0">
            <!-- Variants -->
            <h2>{{ "Variants" | translate }}</h2>
            <div class="card mb-2 p-2" *ngFor="let variant of variants; let i = index" [formGroup]="variant">
                <div class="row align-items-center">
                    <!-- variant name -->
                    <div class="col">
                        <app-variant-name [variant]="offer.variants[i]"></app-variant-name>
                    </div>
                    <!-- price -->
                    <div class="col">
                        <input
                            type="number"
                            class="form-control"
                            placeholder="{{ 'Price' | translate }}"
                            formControlName="price"
                            [class.is-invalid]="variant.get('price').invalid && variant.get('price').touched"
                        />
                    </div>
                    <!-- stocks -->
                    <div class="col">
                        <input
                            type="number"
                            class="form-control"
                            placeholder="{{ 'Stocks' | translate }}"
                            formControlName="stocks"
                            [class.is-invalid]="variant.get('stocks').invalid && variant.get('stocks').touched"
                        />
                    </div>
                    <!-- sku -->
                    <div class="col" *ngIf="variant.contains('sku')">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="{{ 'SKU' | translate }}"
                            formControlName="sku"
                            [class.is-invalid]="variant.get('sku').invalid && variant.get('sku').touched"
                        />
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" (click)="save()">{{ "Save" | translate }}</button>
        </div>
    </form>
</ion-content>
