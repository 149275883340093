import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { AddressListComponent } from 'src/app/components/address-list/address-list.component';

@Component({
    selector: 'app-pick-address-modal',
    templateUrl: './pick-address-modal.component.html',
    styleUrls: ['./pick-address-modal.component.scss'],
    imports: [CommonModule, IonContent, TranslatePipe, AddressListComponent, FormsModule],
})
export class PickAddressModalComponent implements OnInit {
    @Input() isProfile: boolean = false;
    pickedAddressId: number;

    constructor(private readonly modalCtrl: ModalController) {}

    ngOnInit() {}

    pickAddress() {
        this.modalCtrl.dismiss({ addressId: this.pickedAddressId });
    }

    cancel() {
        this.modalCtrl.dismiss();
    }
}
