import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import Decimal from 'decimal.js';
import { filter, first } from 'rxjs';
import { SelectComponent } from 'src/app/components/select/select.component';
import { VariantNameComponent } from 'src/app/components/variant-name/variant-name.component';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { Offer } from '../../models/offer';
import { CategoryService } from '../../services/category.service';
import { CurrencyService } from '../../services/currency.service';
import { OfferService } from '../../services/offer.service';
import { ToastService } from '../../services/toast.service';
import { CategoryUtils } from '../../utils/category.utils';
import { RegexUtils } from '../../utils/regex.utils';

@Component({
    selector: 'app-update-offer-modal',
    templateUrl: './update-offer-modal.component.html',
    styleUrls: ['./update-offer-modal.component.scss'],
    imports: [CommonModule, IonContent, TranslatePipe, VariantNameComponent, ReactiveFormsModule, SelectComponent],
})
export class UpdateOfferModalComponent implements OnInit {
    @Input() offer: Offer;
    form: FormGroup = null;
    currencies = CurrencyService.currencies;
    shippable: boolean;

    constructor(
        private readonly modalCtrl: ModalController,
        private readonly offerService: OfferService,
        private readonly toastService: ToastService,
        private readonly categoryService: CategoryService,
    ) {}

    ngOnInit() {
        this.categoryService.categories
            .pipe(filter((categories) => !!categories))
            .pipe(first())
            .subscribe({
                next: () => {
                    this.offerService.get(this.offer.id).subscribe({
                        next: (offer) => {
                            this.offer = offer;
                            const category = CategoryUtils.getById(this.offer.category);
                            this.shippable = CategoryUtils.findProperty(category, 'shippable');
                            this.form = new FormGroup({
                                name: new FormControl(this.offer.name, [Validators.required]),
                                description: new FormControl(this.offer.description),
                                referencePrice: new FormControl(new Decimal(this.offer.referencePrice || 0).toFixed(2), [
                                    Validators.required,
                                    Validators.pattern(RegexUtils.decimal),
                                ]),
                                referenceCurrency: new FormControl(this.offer.referenceCurrency, [Validators.required]),
                                // Add variants (price, stocks, sku)
                                variants: new FormArray(
                                    this.offer.variants.map((variant) => {
                                        const fg: FormGroup = new FormGroup({
                                            price: new FormControl(new Decimal(variant.price || 0).toFixed(2), [
                                                Validators.required,
                                                Validators.pattern(RegexUtils.decimal),
                                            ]),
                                            stocks: new FormControl(variant.stocks, [
                                                Validators.required,
                                                Validators.min(0),
                                                Validators.pattern(RegexUtils.integer),
                                            ]),
                                        });

                                        if (this.shippable) {
                                            fg.addControl('sku', new FormControl(variant.sku, [Validators.required]));
                                        }

                                        return fg;
                                    }),
                                ),
                            });

                            // Add package weight
                            if (this.shippable) {
                                // Add days to ship
                                this.form.addControl(
                                    'daysToShip',
                                    new FormControl(this.offer.daysToShip, [Validators.required, Validators.pattern(RegexUtils.integer)]),
                                );
                            }

                            // If no variants, add stocks and sku field
                            if (this.offer.variants.length === 0) {
                                this.form.addControl(
                                    'stocks',
                                    new FormControl(this.offer.stocks, [
                                        Validators.required,
                                        Validators.min(0),
                                        Validators.pattern(RegexUtils.integer),
                                    ]),
                                );
                                if (this.shippable) {
                                    this.form.addControl('sku', new FormControl(this.offer.sku, [Validators.required]));
                                }
                            }
                        },
                    });
                },
            });
    }

    get variants(): FormGroup[] {
        return (this.form.get('variants') as FormArray).controls as FormGroup[];
    }

    cancel() {
        this.modalCtrl.dismiss();
    }

    async save() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        await LoadingUtils.show();
        const newValues = this.form.value;
        // Make sure price is formatted correctly
        newValues.referencePrice = new Decimal(newValues.referencePrice).toFixed(2);
        newValues.variants = newValues.variants.map((variant: { price: string }) => {
            variant.price = variant.price ? new Decimal(variant.price).toFixed(2) : null;
            return variant;
        });
        // get first currency in array
        newValues.referenceCurrency = Array.isArray(newValues.referenceCurrency)
            ? newValues.referenceCurrency[0]
            : newValues.referenceCurrency;
        console.log(newValues);
        // Send new values to api
        this.offerService.updateOffer(this.offer.id, newValues).subscribe({
            next: () => {
                this.modalCtrl.dismiss();
                this.toastService.success(_('Your offer has been updated'));
                LoadingUtils.hide();
            },
        });
    }
}
