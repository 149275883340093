import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import {
    ActionSheetController,
    IonContent,
    IonMenu,
    IonMenuToggle,
    IonRouterLinkWithHref,
    IonSpinner,
    LoadingController,
    MenuController,
    ModalController,
} from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SettingsModalComponent } from '../../modals/settings-modal/settings-modal.component';
import { Category } from '../../models/category';
import { AppPlatform } from '../../models/platform';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { CategoryService } from '../../services/category.service';
import { ContextService } from '../../services/context.service';
import { ModalService } from '../../services/modal.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { LocaleDefinition, TranslateService } from '../../services/translate.service';
import { UserService } from '../../services/user.service';
import { CategoryUtils } from '../../utils/category.utils';
import { HeaderCategoryListComponent } from '../header-category-list/header-category-list.component';
import { PictureComponent } from '../picture/picture.component';
import { SearchFormComponent } from '../search-form/search-form.component';

const dropdownAnimation = [
    state('void', style({ height: 0 })),
    state('*', style({ height: '*' })),
    transition('void <=> *', animate('300ms ease-in-out')),
];

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger('languageDropdownAnimation', dropdownAnimation),
        trigger('regionDropdownAnimation', dropdownAnimation),
        trigger('loginDropdownAnimation', dropdownAnimation),
        trigger('userDropdownAnimation', dropdownAnimation),
    ],
    imports: [
        CommonModule,
        IonRouterLinkWithHref,
        IonMenu,
        IonContent,
        IonMenuToggle,
        IonSpinner,
        TranslatePipe,
        RouterLink,
        PictureComponent,
        HeaderCategoryListComponent,
        NgOptimizedImage,
        SearchFormComponent,
    ],
})
export class HeaderComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    @Input() public title: any;
    @Input() searchForm: FormGroup = null;
    public user: User = null;
    public logged = false;
    public categories: Category[] = [];
    public languages: LocaleDefinition[] = TranslateService.LANGUAGES;
    public selectedLanguage: LocaleDefinition = undefined;
    public userDropdownActive = false;
    public loginLoading = false;
    public currentCategory: Category = null;
    promotedCategories: Category[] = [];
    menuCategorySelected: Category;
    version = environment.version;
    childrenCategories: Category[];
    shoppingCartLineCount: number;
    platform: AppPlatform;

    constructor(
        private readonly authService: AuthService,
        private readonly categoryService: CategoryService,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly userService: UserService,
        private readonly modalCtrl: ModalController,
        private readonly modalService: ModalService,
        private readonly ngZone: NgZone,
        private readonly menuCtrl: MenuController,
        private readonly loadingCtrl: LoadingController,
        private readonly actionSheetCtrl: ActionSheetController,
        private readonly shoppingCartService: ShoppingCartService,
        private readonly contextService: ContextService,
    ) {}

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    ngOnInit() {
        this.categoryService.categories.subscribe((categories) => {
            if (categories && categories.length > 0) {
                this.categories = categories;
                // this.promotedCategories = CategoryUtils.findPromoted();
                this.initRouter();
            }
        });
        this.subscriptions.push(
            this.authService.userLoggedIn.subscribe((user) => {
                this.ngZone.run(() => {
                    this.logged = !!user;
                    this.user = user;
                    this.loginLoading = false;
                });
            }),
        );
        this.translateService.localeChanged.subscribe((locale) => {
            this.selectedLanguage = this.languages.find((l) => l.code === locale);
        });
        this.shoppingCartService.shoppingCart.subscribe((shoppingCart) => {
            if (shoppingCart) {
                this.shoppingCartLineCount = shoppingCart.count;
            }
        });
        this.platform = this.contextService.getPlatform();
        this.categoryService.getPromotedCategories().subscribe((categories) => {
            this.promotedCategories = categories;
        });
    }

    private initRouter() {
        // List to url change
        this.subscriptions.push(
            this.router.events.subscribe((event) => {
                if (event.constructor.name === 'NavigationEnd') {
                    // Get the current category from the url query param "category"
                    const categoryId = +(this.router.parseUrl(this.router.url).queryParams as any).category;
                    const category = CategoryUtils.getById(categoryId);
                    if (category) {
                        this.currentCategory = CategoryUtils.findRootCategory(category);
                    }
                }
            }),
        );
    }

    isActive(category: Category): boolean {
        return this.currentCategory === category;
    }

    openCategoryMenu(event: Event) {
        event.preventDefault();
        this.menuCtrl.enable(true, 'main-menu');
        this.menuCtrl.open('main-menu');
        this.menuCategorySelected = null;
    }

    menuSelectCategory(category: Category) {
        this.menuCategorySelected = category;
        this.childrenCategories = category.children;
    }

    menuCategoryGoBack() {
        this.menuCategorySelected = CategoryUtils.getById(this.menuCategorySelected.parent);
        this.childrenCategories = (this.menuCategorySelected?.children || []).filter((c) => c.hasOffersOnPlatform(this.platform));
    }

    openSettingsModal(event: Event) {
        event.preventDefault();
        this.modalCtrl
            .create({
                component: SettingsModalComponent,
            })
            .then((m) => m.present());
    }
}
