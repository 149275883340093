import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-profile-tab-stats',
    templateUrl: './profile-tab-stats.page.html',
    styleUrls: ['./profile-tab-stats.page.scss'],

    standalone: true,
})
export class ProfileTabStatsPage implements OnInit {
    constructor() {}

    ngOnInit() {}
}
