<form (submit)="search()" *ngIf="searchForm" [formGroup]="searchForm">
    <div class="fields-container">
        <div *ngIf="searchForm.controls.hasOwnProperty('query')" class="input-container">
            <input formControlName="query" class="form-control" [placeholder]="'What are you looking for ?' | translate" />
        </div>
        <div *ngIf="searchForm.controls.hasOwnProperty('category')" class="select-container">
            <select formControlName="category" class="form-select">
                <option value="" selected>{{ "Category" | translate }}</option>
                <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</option>
            </select>
        </div>
    </div>
    <button type="submit" class="btn btn-primary">
        <span class="bi bi-search"></span>
    </button>
</form>
