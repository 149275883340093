import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatePipe, _ } from '@ngx-translate/core';
import Decimal from 'decimal.js';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { PriceComponent } from 'src/app/components/price/price.component';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { Card } from '../../../../models/transaction';
import { CardService } from '../../../../services/card.service';
import { ToastService } from '../../../../services/toast.service';
import { WalletService } from '../../../../services/wallet.service';
import { IbanValidator } from '../../../../validators/iban.validator';

@Component({
    selector: 'app-profile-tab-wallet',
    templateUrl: './profile-tab-wallet.component.html',
    styleUrls: ['./profile-tab-wallet.component.scss'],
    imports: [CommonModule, PriceComponent, TranslatePipe, LoaderComponent, CommonModule, FormsModule, ReactiveFormsModule],
})
export class ProfileTabWalletComponent implements OnInit {
    cards: Card[];
    iban: string;
    loadingIban: boolean = true;
    ibanForm = new FormGroup({
        iban: new FormControl('', [Validators.required, IbanValidator]),
    });

    constructor(
        private readonly cardService: CardService,
        private readonly walletService: WalletService,
        private readonly toastService: ToastService,
    ) {}

    ngOnInit() {
        this.loadCards();
        // Load iban
        this.walletService.getIban().subscribe({
            next: (res) => {
                this.ibanForm.setValue({ iban: res.iban });
                this.loadingIban = false;
            },
            error: (err) => {
                console.log(err);
                this.loadingIban = false;
            },
        });
    }

    loadCards() {
        this.cardService.list().subscribe({
            next: (cards) => {
                this.cards = cards;
            },
            error: (err) => {
                console.log(err);
            },
        });
    }

    async deleteCard(card: Card) {
        await LoadingUtils.show();
        this.cardService.deleteCard(card).subscribe({
            next: () => {
                LoadingUtils.hide();
                this.loadCards();
            },
            error: (err) => {
                console.log(err);
            },
        });
    }

    getWalletBalance() {
        return { price: new Decimal(0), currency: 'TRY' };
    }

    updateIban() {
        if (this.ibanForm.invalid) {
            this.ibanForm.markAllAsTouched();
            return;
        }
        this.loadingIban = true;
        this.walletService.changeIban(this.ibanForm.value.iban).subscribe({
            next: () => {
                this.loadingIban = false;
                this.toastService.success(_('Changes saved'));
            },
            error: (err) => {
                console.log(err);
                this.loadingIban = false;
                this.toastService.error(_('An error occurred'));
            },
        });
    }
}
