import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { Order } from '../../models/order';
import { PictureComponent } from '../picture/picture.component';
import { PriceComponent } from '../price/price.component';
import { VariantNameComponent } from '../variant-name/variant-name.component';

@Component({
    selector: 'app-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss'],
    imports: [CommonModule, TranslatePipe, CommonModule, PriceComponent, VariantNameComponent, RouterLink, PictureComponent],
})
export class OrderSummaryComponent implements OnInit {
    @Input() showTitle = true;
    @Input() showDetailButton = true;
    @Input() order: Order;

    constructor() {}

    ngOnInit() {}
}
