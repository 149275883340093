<div class="card p-4">
    <ng-container *ngIf="offers?.length > 0">
        <div class="table-responsive">
            <table class="table mw-850">
                <thead>
                    <tr>
                        <th></th>
                        <th>{{ 'Offer title'|translate }}</th>
                        <th>{{ 'Price'|translate }}</th>
                        <th>{{ 'Stocks'|translate }}</th>
                        <th>{{ 'Category'|translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let offer of offers">
                        <td>
                            <app-picture [picture]="offer.medias[0]" size="50x50" *ngIf="offer.medias.length > 0"></app-picture>
                        </td>
                        <td>{{ offer.name }}</td>
                        <td><app-price [price]="offer.priceObject"></app-price></td>
                        <td>{{ offer.totalStocks }} <span class="fw-bold">({{ offer.totalStocksPending * -1 }})</span></td>
                        <td>{{ offer.categoryName }}</td>
                        <td class="text-end">
                            <div class="text-nowrap d-inline-flex justify-content-end gap-2">
                                <button type="button" class="btn btn-link-primary" (click)="updateOffer(offer)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                                <button type="button" class="btn btn-link-danger" (click)="removeOffer(offer)">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="offers?.length === 0">
        <div class="py-4">
            <em>{{ 'You don\'t have any offers yet'|translate }}</em>
        </div>
    </ng-container>

    <ng-container *ngIf="!offers">
        <div class="py-4 text-center">
            <app-loader></app-loader>
        </div>
    </ng-container>
</div>
