import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { PasswordLostModalComponent } from '../../../../modals/password-lost-modal/password-lost-modal.component';
import { User } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';

@Component({
    selector: 'app-profile-tab-account-security',
    templateUrl: './profile-tab-account-security.component.html',
    styleUrls: ['./profile-tab-account-security.component.scss'],
    imports: [CommonModule, TranslatePipe],
})
export class ProfileTabAccountSecurityComponent implements OnInit {
    user: User;
    constructor(
        private readonly authService: AuthService,
        private readonly modalCtrl: ModalController,
    ) {}

    ngOnInit() {
        this.authService.userLoggedIn.subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });
    }

    async changePassword() {
        const modal = await this.modalCtrl.create({
            component: PasswordLostModalComponent,
            backdropDismiss: false,
            componentProps: {
                email: this.user.email,
            },
        });
        modal.present();
    }
}
