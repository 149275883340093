import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { TeamAccountMemberComponent } from 'src/app/components/team-account-member/team-account-member.component';
import { TeamAccountRequestComponent } from 'src/app/components/team-account-request/team-account-request.component';
import { TeamAccountRequest } from '../../../../models/company';
import { User } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';
import { CompanyService } from '../../../../services/company.service';

@Component({
    selector: 'app-profile-tab-team',
    templateUrl: './profile-tab-team.page.html',
    styleUrls: ['./profile-tab-team.page.scss'],
    imports: [CommonModule, TranslatePipe, TeamAccountMemberComponent, TeamAccountRequestComponent],
})
export class ProfileTabTeamPage implements OnInit {
    requests: TeamAccountRequest[] = [];
    members: User[] = [];
    requestLoading = false;
    membersLoading = false;

    constructor(
        private readonly companyService: CompanyService,
        private readonly authService: AuthService,
        private readonly navCtrl: NavController,
    ) {}

    ngOnInit() {
        this.initRequests();
    }

    initRequests() {
        this.requestLoading = true;
        this.membersLoading = true;
        this.authService.userLoggedIn.subscribe((user) => {
            if (user) {
                // Check if user is pro
                if (user.type !== 'pro') {
                    this.navCtrl.navigateRoot('/profile');
                    return;
                }
                this.companyService.getMyTeamAccountRequests().subscribe({
                    next: (requests) => {
                        this.requests = requests;
                        this.requestLoading = false;
                    },
                });
                this.companyService.getTeam(user.company).subscribe({
                    next: (members) => {
                        this.members = members;
                        this.membersLoading = false;
                    },
                });
            }
        });
    }
}
