<ng-container *ngIf="ads?.length > 0">
    <div *ngFor="let ad of ads">
        <span>{{ ad.name }}</span>
    </div>
</ng-container>

<ng-container *ngIf="ads?.length === 0">
    <div class="py-4">
        <em>{{ 'You don\'t have any ads yet'|translate }}</em>
    </div>
</ng-container>
