<div class="image-container d-flex justify-content-center align-items-center" [style.width]="width" [style.height]="height">
    <ng-container *ngIf="loading">
        <!-- loader -->
        <app-loader></app-loader>
        <div class="pb">
            <div class="pb-content" [style.width]="progress + '%'"></div>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading">
        <ng-content></ng-content>
    </ng-container>
</div>
