import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { PriceComponent } from 'src/app/components/price/price.component';
import { Order } from '../../../../models/order';
import { OrderService } from '../../../../services/order.service';

@Component({
    selector: 'app-profile-tab-sales',
    templateUrl: './profile-tab-sales.component.html',
    styleUrls: ['./profile-tab-sales.component.scss'],
    imports: [CommonModule, TranslatePipe, LoaderComponent, RouterLink, PriceComponent, DatePipe],
})
export class ProfileTabSalesComponent implements OnInit {
    orders: Order[];

    constructor(private readonly orderService: OrderService) {}

    ngOnInit() {
        this.orderService.getSales().subscribe({
            next: (result) => {
                this.orders = result.items;
                console.log(this.orders);
            },
        });
    }
}
