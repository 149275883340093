<ng-container *ngIf="!orders; else orderList">
    <div class="text-center">
        <app-loader></app-loader>
    </div>
</ng-container>
<ng-template #orderList>
    <ng-container *ngIf="orders.length === 0">
        <div class="py-4 text-center">
            {{ "No orders found" | translate }}
        </div>
    </ng-container>
    <div class="pb-3" *ngFor="let order of orders">
        <app-order-summary [order]="order"></app-order-summary>
    </div>
</ng-template>
